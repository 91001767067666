import { FieldConfig } from "../types/FormDataTypes";
type Validator = (value: string, ...params: string[]) => string | null;

interface ValidationRules {
  [key: string]: Validator;
}

const validators: ValidationRules = {
  required: (value: string) =>
    value.trim() === "" ? "This field is required" : null,
  minLength: (value: string, min: string) =>
    value.length < parseInt(min) ? `Minimum length is ${min}` : null,
  maxLength: (value: string, max: string) =>
    value.length > parseInt(max) ? `Maximum length is ${max}` : null,
  isEmail: (value: string) =>
    !/\S+@\S+\.\S+/.test(value) ? "Please enter a valid email address" : null,
  isDate: (value: string) =>
    isNaN(Date.parse(value)) ? "Please enter a valid date" : null,
  isAlpha: (value: string) =>
    !/^[A-Za-z\s]+$/.test(value)
      ? "This field should only contain alphabets"
      : null,
  isPhoneNumber: (value: string) =>
    !/^\d+$/.test(value) ? "Phone number should contain only numbers" : null,
  phoneLength: (value: string) =>
    value.length !== 10 ? "Phone number must be exactly 10 digits" : null,
};

// formValidation.ts
export const validateField = (
  value: string,
  validationRules: string[] = [],
  isRequired: boolean = false
): string | null => {
  // If the field is not required and the value is empty, skip validation
  if (!isRequired && value.trim() === "") {
    return null; // No error message for empty non-required fields
  }

  // Loop through the validation rules
  for (const rule of validationRules) {
    // Find the corresponding validator function from the validators object
    const validator = validators[rule];

    if (validator) {
      const errorMessage = validator(value, ...rule.split(":").slice(1)); // Pass additional params if present (e.g., min/max length)
      if (errorMessage) {
        return errorMessage;
      }
    }
  }
  return null;
};

// New function to validate all required fields in a tab
export const validateTab = (
  fields: FieldConfig[],
  formState: any,
  activeTabName: string
) => {
  const errors: { [key: string]: string } = {};

  fields.forEach((field) => {
    const value = formState[activeTabName][field.name] || ""; // Get the field value
    const isRequired = field.required || false; // Get if the field is required

    // if (field.required || value.trim() !== "") {
    const error = validateField(value, field.validation, isRequired);
    if (error) {
      errors[field.name] = error;
    }
    // }
  });

  return errors;
};
