import Tippy from "@tippyjs/react";

export type CustomRendereds = {
  [columnKey: string]: (value: any, row: any) => React.ReactNode;
};

const customRenderers: CustomRendereds = {
  id: (value: number) => (
    <Tippy content="Go to student details" placement="bottom">
      <span className="flex justify-center items-center w-11 h-6 rounded-2xl bg-[#35e6db] transition ease-in-out duration-300 hover:bg-[#02beb2] hover:text-white active:scale-[0.9]">
        {value}
      </span>
    </Tippy>
  ),
  add: (value: string) => (
    <span className="flex justify-center items-center gap-x-1 p-1 rounded-3xl transition ease-in-out duration-300 text-white active:scale-[0.9]">
      {value}
    </span>
  ),
  remove: (value: string) => (
    <Tippy content="Remove from absent list" placement="bottom">
      <span className="flex justify-center items-center gap-x-1 p-1 rounded-3xl bg-red-400 transition ease-in-out duration-300 hover:bg-red-500 text-white active:scale-[0.9]">
        {value}
      </span>
    </Tippy>
  ),
};

export default customRenderers;
