import { useState, useEffect } from "react";
import {
  allCampusStandardAndDivision,
  searchedStudent,
} from "../../services/api";

export const useStudentFilters = () => {
  const [studentFilters, setStudentFilters] = useState({
    standard: "",
    section: "",
  });

  const [searchTerm, setSearchTerm] = useState("");

  const [standardAndSectionData, setStandardAndSectionData] =
    useState<any>(null);
  const [selectedStandard, setSelectedStandard] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  const [filtersCleared, setFiltersCleared] = useState(false);

  const [filtersLoading, setFiltersLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const [filtersSubmitted, setFiltersSubmitted] = useState(false);

  const [toastConfig, setToastConfig] = useState<{
    show: boolean;
    message: string;
    type: "error" | "success" | "info";
    duration: number;
  }>({
    show: false,
    message: "",
    type: "error",
    duration: 3000,
  });

  useEffect(() => {
    const fetchFilterData = async () => {
      setFiltersLoading(true);
      try {
        const data = await allCampusStandardAndDivision();
        if (data) {
          setStandardAndSectionData(data.standardAndDivisionMap);
        }
      } catch (error) {
        console.error("Error fetching filter data:", error);
      } finally {
        setFiltersLoading(false);
      }
    };

    fetchFilterData();
  }, []);

  // Handle Search Term Change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleStudentFilterChange = (key: string, value: string) => {
    setStudentFilters((prev) => ({ ...prev, [key]: value }));
    setFiltersSubmitted(false);
  };

  const handleStudentStandardChange = (value: string) => {
    if (!value) {
      setStudentFilters((prev) => ({
        ...prev,
        standard: value,
        section: "",
      }));
    } else {
      const selectedStandardKey = Object.keys(standardAndSectionData).find(
        (key) => key.split(":")[1] === value
      );

      if (selectedStandardKey) {
        setSelectedStandard(selectedStandardKey);
        const sections = standardAndSectionData[selectedStandardKey];

        setStudentFilters((prev) => ({
          ...prev,
          standard: value,
          section: sections && sections.length > 0 ? sections[0] : "",
        }));
      }
    }
  };

  const handleStudentFilterSubmit = async () => {
    if (!studentFilters.standard) {
      setToastConfig({
        show: true,
        message: "Please select a standard.",
        type: "error",
        duration: 3000,
      });

      return;
    }
    const selectedStandardKey = Object.keys(standardAndSectionData).find(
      (key) => key.split(":")[1] === studentFilters.standard
    );

    const params = {
      standard: selectedStandardKey?.split(":")[0] || "",
      studentNameOrId: searchTerm,
      division: studentFilters.section || "",
    };

    try {
      setDataLoading(true);
      const response = await searchedStudent(params);

      if (response) {
        setFilteredData(response); // Assuming the data returned from API is in 'data' field
      } else {
        setFilteredData([]);
      }
    } catch (error) {
      console.error("Error fetching students:", error);
      setFilteredData([]);
    } finally {
      setDataLoading(false);
    }

    setFiltersCleared(false);

    setFiltersSubmitted(true);
  };

  const handleStudentClearSubmit = () => {
    setStudentFilters({
      standard: "",
      section: "",
    });

    setSearchTerm("");

    setFilteredData([]);

    setFiltersCleared(true);
  };

  return {
    studentFilters,
    standardAndSectionData,
    selectedStandard,
    filtersCleared,
    filteredData,
    toastConfig,
    searchTerm,
    filtersLoading,
    dataLoading,
    filtersSubmitted,
    setStudentFilters,
    setStandardAndSectionData,
    setSelectedStandard,
    handleStudentFilterChange,
    handleStudentStandardChange,
    handleStudentFilterSubmit,
    handleStudentClearSubmit,
    handleSearchChange,
    setToastConfig,
    setFiltersSubmitted,
    setFiltersCleared,
  };
};
