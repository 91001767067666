import { useEffect, useState } from "react";
import {
  getAdmissionFormStandardAndDivision,
  getLookupValues,
} from "../../services/api";
import { FormData } from "../../types/FormDataTypes";

const useStudentAdmissionFormOptions = (
  activeTabName: string,
  setFormData: any
) => {
  const [loading, setLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState<any>({});
  const [standardAndDivision, setStandardAndDivision] = useState<any>({});
  const [selectedStandard, setSelectedStandard] = useState<string | "">("");
  const [selectedDivision, setSelectedDivision] = useState<string | "">("");

  // Fetch dynamic options for select fields
  useEffect(() => {
    const params = [
      "STUDENT_GENDER",
      "STUDENT_CATEGORY",
      "STUDENT_BLOOD_GROUP",
    ];
    const fetchOptions = async () => {
      setLoading(true);
      try {
        const response = await getLookupValues(params);
        const formattedOptions: any = {};
        params.forEach((param) => {
          if (response[param]) {
            formattedOptions[param] = response[param].map((item: string) => {
              const [value, label] = item.split(":");
              return { value, label };
            });
          }
        });
        setSelectOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, []);

  // Fetch standard and division data
  useEffect(() => {
    const fetchStandardAndDivision = async () => {
      setLoading(true);
      try {
        const response = await getAdmissionFormStandardAndDivision();

        setStandardAndDivision(response.standardAndDivisionMap);
      } catch (error) {
        console.error("Error fetching standard and division:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStandardAndDivision();
  }, []);

  // Handle standard selection change
  const handleStandardChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStandard = e.target.value;
    setSelectedStandard(selectedStandard);
    setSelectedDivision(""); // Reset division when standard changes
    // Set the selected standard in the formData
    setFormData((prev: FormData) => ({
      ...prev,
      [activeTabName]: {
        ...prev[activeTabName],
        standard: selectedStandard,
      },
    }));
  };

  // Handle division selection change
  const handleDivisionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDivision = e.target.value;
    setSelectedDivision(selectedDivision);
    setFormData((prev: FormData) => ({
      ...prev,
      [activeTabName]: {
        ...prev[activeTabName],
        division: selectedDivision,
      },
    }));
  };

  return {
    loading,
    setLoading,
    setSelectedStandard,
    setSelectedDivision,
    selectOptions,
    selectedStandard,
    handleStandardChange,
    standardAndDivision,
    selectedDivision,
    handleDivisionChange,
  };
};

export default useStudentAdmissionFormOptions;
