import React from "react";

interface LoadingSpinnerProps {
  message?: string;
  size?: string; // Size (e.g., "h-16 w-16")
  color?: string;
  fullScreen?: boolean; // Optional prop to make the spinner cover the full screen
  bgBlur?: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  message = "",
  size = "h-16 w-16",
  color = "border-blue-500",
  fullScreen = true,
  bgBlur = false,
}) => {
  return (
    <div
      className={`flex justify-center items-center ${
        fullScreen && "h-screen"
      } ${bgBlur && "fixed inset-0 z-50 bg-white bg-opacity-50"}`}
    >
      <div className="flex flex-col items-center">
        {/* Spinner with Tailwind classes */}
        <div
          className={`border-t-4 border-solid ${color} rounded-full animate-spin ${size}`}
        ></div>
        {/* Optional message */}
        {message && <p className="mt-5 text-xl">{message}</p>}
      </div>
    </div>
  );
};

export default LoadingSpinner;
