import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Default styles
import LoginPage from "./pages/LoginPage";
import DashboardLayout from "./components/layout/DashboardLayout";
import DashboardHome from "./pages/dashboard/DashboardHome";
import StudentsList from "./pages/dashboard/StudentsList";
import { DashboardProvider } from "./contexts/DashboardContext";
import { StudentsProvider } from "./contexts/StudentsContext";
import StudentDetailsPage from "./pages/dashboard/StudentDetailsPage";
import StudentAdmission from "./pages/dashboard/StudentAdmission";
import LogoutPage from "./pages/LogoutPage";
import TokenErrorPage from "./pages/TokenErrorPage";
import HomeSearchStudent from "./pages/dashboard/HomeSearchStudent";
import AttendancePage from "./pages/dashboard/AttendancePage";
import AdminSettings from "./pages/dashboard/AdminSettings";
import TenantSetup from "./pages/dashboard/TenantSetup";
import CreateTenant from "./pages/dashboard/CreateTenant";
import CreateCampus from "./pages/dashboard/CreateCampus";

const App: React.FC = () => {
  return (
    <StudentsProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/session_expired" element={<TokenErrorPage />} />
          <Route
            path="/*"
            element={
              <DashboardProvider>
                <DashboardLayout>
                  <Routes>
                    {/* Dashboard Home */}
                    <Route path="dashboard" element={<DashboardHome />} />

                    {/* Admin Management Routes */}
                    <Route
                      path="admin_management/attendance"
                      element={<AttendancePage />}
                    />

                    {/* Student Management Routes */}
                    <Route
                      path="student_management/student_list"
                      element={<StudentsList />}
                    />
                    <Route
                      path="student_management/student_list/home_search_student"
                      element={<HomeSearchStudent />}
                    />
                    <Route
                      path="student_management/student_list/student_details/:studentId"
                      element={<StudentDetailsPage />}
                    />
                    <Route
                      path="student_management/stu_admiss_form"
                      element={<StudentAdmission />}
                    />

                    {/* Admin Configuration Routes */}
                    <Route
                      path="admin_config/admin_settings"
                      element={<AdminSettings />}
                    />

                    {/* Master Setup Routes */}
                    <Route
                      path="master_setup/tenant_setup"
                      element={<TenantSetup />}
                    />
                    <Route
                      path="master_setup/tenant_setup/create_tenant"
                      element={<CreateTenant />}
                    />
                    <Route
                      path="master_setup/tenant_setup/create_campus"
                      element={<CreateCampus />}
                    />
                  </Routes>
                </DashboardLayout>
              </DashboardProvider>
            }
          />
        </Routes>
      </Router>
    </StudentsProvider>
  );
};

export default App;
