import React, { useState, useRef, useEffect } from "react";
import { FaSearch } from "react-icons/fa";

// Define the DropdownSearchProps type
interface DropdownSearchProps {
  suggestions: string[] | undefined;
  placeholder?: string;
  onSelect: (tenant: string) => void; // Expecting a string here
  value: string;
}

const DropdownSearch: React.FC<DropdownSearchProps> = ({
  suggestions,
  placeholder,
  onSelect,
  value,
}) => {
  const [query, setQuery] = useState(value);
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setQuery(inputValue);

    if (inputValue) {
      const filtered = suggestions?.filter((item) =>
        item.toLowerCase().includes(inputValue.toLowerCase())
      );
      if (filtered) setFilteredSuggestions(filtered);
      setIsDropdownVisible(true);
    } else {
      if (suggestions) setFilteredSuggestions(suggestions); // Show all suggestions when query is empty
      setIsDropdownVisible(true); // Keep dropdown visible when input is cleared
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setQuery(suggestion);
    setFilteredSuggestions([]);
    setIsDropdownVisible(false);
    onSelect(suggestion); // Trigger onSelect with the selected suggestion
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      event.target instanceof Element &&
      !event.target.closest(".search-component")
    ) {
      setIsDropdownVisible(false);
    }
  };

  // Handle input field focus to show the dropdown immediately
  const handleFocus = () => {
    if (query.trim() === "") {
      if (suggestions) setFilteredSuggestions(suggestions); // Show all suggestions on focus if no query is entered
      setIsDropdownVisible(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const dropdownWidth = inputRef.current
    ? inputRef.current.offsetWidth
    : "auto";

  return (
    <div className="relative search-component">
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={query}
          onChange={handleInputChange}
          onFocus={handleFocus} // Trigger dropdown visibility on focus
          placeholder={placeholder || "Search..."}
          className="search-input pl-3 pr-9 py-1 border border-[#ccc] rounded w-72"
        />
        {/* Add search icon inside the input on the right side */}
        <FaSearch className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500" />
      </div>
      {isDropdownVisible && (
        <div
          className={`absolute top-full left-0 right-0 bg-white border border-[#ccc] rounded z-[1000] max-h-52 overflow-y-auto w-[${dropdownWidth}]`}
        >
          {filteredSuggestions.length > 0 ? (
            filteredSuggestions.map((suggestion, index) => (
              <div
                key={index}
                className="p-2 cursor-pointer border-b border-b-[#eee] hover:bg-gray-200"
                onClick={() => handleSuggestionClick(suggestion)} // Ensure the suggestion click triggers onSelect
              >
                {suggestion}
              </div>
            ))
          ) : (
            <div className="p-2 cursor-pointer text-[#888] text-center">
              No data found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownSearch;
