import React from "react";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

interface BackButtonProps {
  label?: string; // Optional label for the button, default is "Go Back"
  to?: string; // Optional 'to' prop to specify the destination route
  state?: any; // Optional 'state' prop to specify the state to pass during navigation
}

const BackButton: React.FC<BackButtonProps> = ({
  label = "Go Back",
  to,
  state,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (to) {
      navigate(to, { state }); // Navigate to the specified route with the provided state
    } else {
      navigate(-1); // If no 'to' prop, go back to the previous page without any state
    }
  };

  return (
    <div>
      <motion.button
        onClick={handleClick}
        className="py-1 px-3 rounded-lg flex gap-x-1 items-center transform transition-all duration-200 ease-in-out bg-[#fdb8c7] hover:bg-[#f59eb1] hover:text-white"
        whileHover={{
          scale: 1.05, // Scale on hover
        }}
        whileTap={{
          scale: 0.95, // Scale down on tap/click
        }}
      >
        <MdOutlineKeyboardDoubleArrowLeft size={24} />
        {label}
      </motion.button>
    </div>
  );
};

export default BackButton;
