import React, { useEffect, useState } from "react";
import { homeSearchResults } from "../../services/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import FilterPanel from "../../components/common/FilterPanel";
import DataTable from "../../components/common/DataTable";
import { homeSearchVisibleColumns } from "../../utils/tableVisibleColumns";
import { useStudentFilters } from "../../hooks/attendanceFilters/studentFilters";
import { studentFilterOptions } from "../../utils/filterOptions";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import Toast from "../../components/common/Toast";

const HomeSearchStudent: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [filteredSearchData, setFilteredSearchData] = useState<any[]>([]);
  const [displayData, setDisplayData] = useState<any[]>([]);
  const [filterDataLoading, setFilterDataLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") || "";
  const searchType = searchParams.get("searchType") || "";

  const {
    studentFilters,
    standardAndSectionData,
    selectedStandard,
    filtersCleared,
    toastConfig,
    filtersLoading,
    filtersSubmitted,
    handleStudentFilterChange,
    handleStudentStandardChange,
    setToastConfig,
    setStudentFilters,
    setStandardAndSectionData,
    setSelectedStandard,
    setFiltersSubmitted,
    setFiltersCleared,
  } = useStudentFilters();

  const location = useLocation(); // Use location to detect if coming from StudentDetailsPage

  const savedState = sessionStorage.getItem("searchedStudentListState");

  useEffect(() => {
    if (location.state?.fromStudentDetails && savedState) {
      const parsedState = JSON.parse(savedState);

      // If there are no filters in the session storage, load the last submitted filters
      const lastSubmittedFilters = JSON.parse(
        sessionStorage.getItem("lastSubmittedFilters") || "{}"
      );

      setStudentFilters({
        standard:
          lastSubmittedFilters?.standard ||
          parsedState?.studentFilters?.standard ||
          "",
        section:
          lastSubmittedFilters?.section ||
          parsedState?.studentFilters?.section ||
          "",
      });

      setFilteredSearchData(parsedState?.filteredSearchData);

      setDisplayData(parsedState?.displayData);

      setStandardAndSectionData(parsedState?.standardAndSectionData);

      setSelectedStandard(parsedState?.selectedStandard);
    } else {
      sessionStorage.removeItem("searchedStudentListState");
      sessionStorage.removeItem("lastSubmittedFilters");

      const fetchResults = async () => {
        setLoading(true);
        try {
          const response = await homeSearchResults({ search, searchType });

          if (response) {
            setFilteredSearchData(response);
            setDisplayData(response);
          } else {
            // Set displayData to an empty array or handle 'no data' message
            setFiltersCleared(false);
            setFilteredSearchData([]);
            setDisplayData([]);
          }

          handleClearSubmit();
        } catch (error) {
          setFiltersCleared(false);
          setFilteredSearchData([]);
          setDisplayData([]);
          console.error("Error fetching search results:", error);
        } finally {
          setLoading(false);
        }
      };

      if (search && searchType) {
        fetchResults();
      }
    }
  }, [search, searchType, location.state?.fromStudentDetails]);

  useEffect(() => {
    if (filteredSearchData && !savedState) setDisplayData(filteredSearchData);
  }, [filteredSearchData]);

  const handleFilterSubmit = () => {
    if (!studentFilters.standard) {
      setToastConfig({
        show: true,
        message: "Please select a standard.",
        type: "error",
        duration: 3000,
      });

      return;
    }

    setFilterDataLoading(true);

    try {
      let filteredStudentData;
      if (
        studentFilters.section.toLowerCase() === "all" ||
        studentFilters.section === ""
      ) {
        // If "all" or empty, get all students with the selected standard (ignoring section)
        filteredStudentData = filteredSearchData.filter(
          (student) => student.standard === studentFilters.standard
        );
      } else {
        // Otherwise, filter based on both standard and section
        filteredStudentData = filteredSearchData.filter((student) => {
          return (
            student.standard === studentFilters.standard &&
            student.division === studentFilters.section
          );
        });
      }

      setDisplayData(filteredStudentData);
    } finally {
      setFilterDataLoading(false);
    }

    sessionStorage.setItem(
      "lastSubmittedFilters",
      JSON.stringify({
        standard: studentFilters.standard,
        section: studentFilters.section,
      })
    );

    setFiltersSubmitted(true);
  };

  const handleClearSubmit = () => {
    setStudentFilters({
      standard: "",
      section: "",
    });

    sessionStorage.removeItem("lastSubmittedFilters");

    setDisplayData(filteredSearchData);

    setFiltersCleared(true);
  };

  // Handle Cell Click
  const handleCellClick = (columnKey: string, row: any) => {
    if (columnKey === "id") {
      if (filtersSubmitted) {
        sessionStorage.setItem(
          "searchedStudentListState",
          JSON.stringify({
            studentFilters,
            filteredSearchData,
            displayData,
            standardAndSectionData,
            selectedStandard,
          })
        );
      } else {
        sessionStorage.setItem(
          "searchedStudentListState",
          JSON.stringify({
            filteredSearchData,
            displayData,
            standardAndSectionData,
            selectedStandard,
          })
        );
      }

      navigate(`/student_management/student_list/student_details/${row.id}`, {
        state: { from: "/student_management/student_list/home_search_student" }, // Pass the referring page
      });
    }
  };

  return (
    <>
      {filtersLoading ? (
        <LoadingSpinner message="Loading..." />
      ) : (
        <>
          {(filterDataLoading || loading) && <LoadingSpinner bgBlur={true} />}
          <div className="bg-slate-100">
            {/* Filter Panel */}
            <FilterPanel
              heading="Student Information"
              filters={studentFilterOptions(
                standardAndSectionData,
                selectedStandard,
                studentFilters,
                handleStudentStandardChange,
                handleStudentFilterChange
              )}
              onFilterSubmit={handleFilterSubmit}
              containerStyle="max-w-full"
              showSearch={false}
              onClearSubmit={handleClearSubmit}
            />

            <div className="mt-3">
              <DataTable
                data={displayData}
                onCellClick={handleCellClick}
                clickableColumns={["id"]}
                visibleColumns={homeSearchVisibleColumns} // Pass visible columns to DataTable
                filtersCleared={filtersCleared}
                defaultPageSize={20}
              />
            </div>

            <Toast
              message={toastConfig.message}
              type={toastConfig.type}
              show={toastConfig.show}
              onClose={() =>
                setToastConfig((prev) => ({ ...prev, show: false }))
              }
              duration={toastConfig.duration}
              position="top"
            />
          </div>
        </>
      )}
    </>
  );
};

export default HomeSearchStudent;
