import { motion } from "framer-motion";
import { useEffect } from "react";
import {
  IoIosAlert,
  IoIosCheckmarkCircleOutline,
  IoIosInformationCircleOutline,
} from "react-icons/io";
import { IoClose } from "react-icons/io5";

interface ToastProps {
  message: string;
  type: "error" | "success" | "info"; // Type of toast (error, success, info)
  show: boolean;
  onClose: () => void;
  duration?: number; // How long the toast stays visible (default is 3000ms)
  position?: "top" | "bottom"; // Position of the toast on the screen (default is 'bottom')
}

const Toast: React.FC<ToastProps> = ({
  message,
  type,
  show,
  onClose,
  duration = 3000,
  position = "bottom",
}) => {
  const icons = {
    error: <IoIosAlert className="text-2xl" />,
    success: <IoIosCheckmarkCircleOutline className="text-2xl" />,
    info: <IoIosInformationCircleOutline className="text-2xl" />,
  };

  const backgroundColors = {
    error: "bg-red-500",
    success: "bg-green-500",
    info: "bg-blue-500",
  };

  // Automatically close the toast after the given duration
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);

      return () => clearTimeout(timer); // Cleanup timer on unmount or when show is false
    }
  }, [show, duration, onClose]);

  // Animate the toast from the top or bottom
  const initialPosition = position === "top" ? "-100%" : "100%";
  const exitPosition = position === "top" ? "-100%" : "100%";

  return (
    <motion.div
      className={`fixed w-full h-16 ${
        position === "top" ? "top-0" : "bottom-5"
      } left-0 transform -translate-x-1/2 flex items-center justify-between ${
        backgroundColors[type]
      } text-white px-8 py-2 shadow-lg z-[9999]`}
      initial={{ y: initialPosition }} // Start off-screen
      animate={{ y: show ? 0 : exitPosition }} // Slide to visible or off-screen
      exit={{ y: exitPosition }} // Exit off-screen in the same direction
      transition={{ duration: 0.3 }} // Smooth transition
    >
      <div className="flex-1 flex items-center justify-center gap-2">
        {icons[type]}
        <span>{message}</span>
      </div>

      {/* Close Button */}
      <button
        onClick={onClose}
        className="hover:text-gray-200 focus:outline-none"
      >
        {/* <IoIosClose className="text-2xl font-bold" /> */}
        <IoClose className="text-2xl" />
      </button>
    </motion.div>
  );
};

export default Toast;
