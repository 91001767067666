import React, { useEffect, useState } from "react";
import ProgressBar from "../../common/ProgressBar";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { validateField, validateTab } from "../../../utils/formValidation";
import Toast from "../../common/Toast";
import { FiAlertTriangle } from "react-icons/fi";
import Accordion from "../../common/Accordion";
import Popup from "../../common/Popup";
import LoadingSpinner from "../../common/LoadingSpinner";
import { formatDate } from "../../../utils/formatDate";
import { FormData } from "../../../types/FormDataTypes";
import { tabKeys, tabTitles } from "../../../utils/studentAdmissionTabNames";
import studentAdmissionFieldLabels from "../../../hooks/studentAdmission/useStudentAdmissionFieldLabels";
import useStudentAdmissionFormOptions from "../../../hooks/studentAdmission/useStudentAdmissionFormOptions";
import useStudentAdmissionHandlePopup from "../../../hooks/studentAdmission/useStudentAdmissionHandlePopup";

const AdmissionForm = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabName, setActiveTabName] = useState("");
  const [formData, setFormData] = useState<FormData>({
    studentInfo: {},
    parentInfo: {},
    admissionDetails: {},
    additionalInfo: {},
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const { fields, rows, fieldConfigMap } =
    studentAdmissionFieldLabels(activeTabName);

  const {
    loading,
    setLoading,
    setSelectedStandard,
    setSelectedDivision,
    selectOptions,
    selectedStandard,
    handleStandardChange,
    standardAndDivision,
    selectedDivision,
    handleDivisionChange,
  } = useStudentAdmissionFormOptions(activeTabName, setFormData);

  const getTrimmedFormData = () => {
    // Trim all form data before submitting
    const trimFormData: FormData = Object.keys(formData).reduce((acc, key) => {
      // Ensure the current key is valid and of type FormData (not empty object)
      if (formData[key]) {
        acc[key] = Object.keys(formData[key]).reduce((nestedAcc, nestedKey) => {
          nestedAcc[nestedKey] = formData[key][nestedKey]?.trim() || "";
          return nestedAcc;
        }, {} as { [key: string]: string });
      }
      return acc;
    }, {} as FormData);

    return trimFormData;
  };

  const {
    setToastConfig,
    handleConfirm,
    showConfirmPopup,
    setShowConfirmPopup,
    handleSubmit,
    showSubmitPopup,
    handleGoToStudentDetails,
    handleNewAdmit,
    toastConfig,
  } = useStudentAdmissionHandlePopup(
    getTrimmedFormData,
    setLoading,
    setFormData,
    setErrors,
    setActiveTab,
    setSelectedStandard,
    setSelectedDivision
  );

  useEffect(() => {
    setActiveTabName(tabKeys[activeTab]);
  }, [activeTab]);

  // Update handleChange to work with both input and select elements
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    // Use a type guard to check if the target is an HTMLInputElement (file input)
    if (
      e.target instanceof HTMLInputElement &&
      name === "photo" &&
      e.target.files
    ) {
      const fileName = e.target.files[0]?.name;
      if (fileName) {
        setFormData((prev: any) => ({
          ...prev,
          [activeTabName]: {
            ...prev[activeTabName],
            [name]: fileName, // Store the file name
          },
        }));
      }
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [activeTabName]: {
        ...prev[activeTabName],
        [name]: value,
      },
    }));

    // Validate field if necessary
    const field = fields.find((f) => f.name === name);
    if (field && field.validation) {
      // Validate the field dynamically
      const error = validateField(value, field.validation);
      setErrors((prev) => ({
        ...prev,
        [name]: error || "",
      }));
    }
  };

  const handleNext = () => {
    if (activeTab === tabTitles.length - 1) return; // Prevent going beyond the last tab

    // Validate only if it's not the preview tab
    if (activeTab !== tabTitles.length - 2) {
      const validationErrors = validateTab(fields, formData, activeTabName);

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors); // Show validation errors
        setToastConfig({
          show: true,
          message: "Please fill all the required fields",
          type: "error",
          duration: 3000,
        });
        return;
      }
    }
    setActiveTab(activeTab + 1); // Move to the next tab
    setErrors({}); // Clear any previous errors
  };

  const handlePrevious = () => {
    if (activeTab > 0) {
      setActiveTab(activeTab - 1);
    }
  };

  const getLabelFromOptions = (field: string, value: string) => {
    const fieldOptions = selectOptions[`STUDENT_${field.toUpperCase()}`];
    if (fieldOptions) {
      const option = fieldOptions.find((option: any) => option.value === value);
      return option ? option.label : "N/A";
    }
    return "N/A";
  };

  return loading ? (
    <LoadingSpinner message="Loading..." />
  ) : (
    <div className="w-full px-6 pt-3 pb-5 bg-[#b8efe53b] shadow-lg rounded-md">
      <h1 className="text-3xl font-bold text-blue-600 mb-6 text-center font-admissionForm">
        Admission Form
      </h1>

      {/* Progress Bar */}
      <ProgressBar
        activeTab={activeTab}
        tabTitles={tabTitles}
        // handleNext={handleNext} // Pass handleNext as a prop
        // handlePrevious={handlePrevious} // Pass handlePrevious as a prop
      />

      {/* Tab Content */}
      <div className="px-8 py-6 max-w-6xl h-[24rem] overflow-y-auto mx-auto admissionForm-scrollbar">
        {activeTab === tabTitles.length - 1 ? ( // Check if it's the preview tab
          <>
            <Accordion
              title="Student Information"
              defaultOpen={true}
              content={
                <div className="flex justify-center">
                  <div className="w-2/3">
                    <form className="space-y-4">
                      {/* Display Full Name */}
                      <div className="flex flex-row gap-4 items-start">
                        <div className="font-semibold w-1/3 text-left">
                          Full Name:
                        </div>
                        <div className="w-2/3">
                          {formData.studentInfo?.firstName}{" "}
                          {formData.studentInfo?.middleName &&
                            `${formData.studentInfo?.middleName} `}
                          {formData.studentInfo?.lastName}
                        </div>
                      </div>

                      {/* Other Fields */}
                      {Object.keys(formData.studentInfo || {}).map((key) => {
                        if (
                          key === "firstName" ||
                          key === "middleName" ||
                          key === "lastName"
                        )
                          return null; // Skip individual name fields

                        if (!formData.studentInfo[key]) return null;

                        const label = fieldConfigMap.studentInfo?.[key];

                        return (
                          <div
                            key={key}
                            className="flex flex-row gap-4 items-start"
                          >
                            <div className="font-semibold w-1/3 text-left">
                              {(label || key) + ": "}
                            </div>
                            <div className="w-2/3">
                              {key === "gender" || key === "blood_group"
                                ? getLabelFromOptions(
                                    key,
                                    formData.studentInfo[key]
                                  )
                                : key === "dob"
                                ? formatDate(formData.studentInfo?.dob || "")
                                : formData.studentInfo[key] || "N/A"}
                            </div>
                          </div>
                        );
                      })}
                    </form>
                  </div>
                </div>
              }
            />

            <Accordion
              title="Admission Details"
              defaultOpen={true}
              content={
                <div className="flex justify-center">
                  <div className="w-2/3">
                    <form className="space-y-4">
                      {Object.keys(formData.admissionDetails || {}).map(
                        (key) => {
                          if (!formData.admissionDetails?.[key]) return null;

                          const label = fieldConfigMap.admissionDetails?.[key];

                          return (
                            <div
                              key={key}
                              className="flex flex-row gap-4 items-start"
                            >
                              <div className="font-semibold w-1/3 text-left">
                                {(label || key) + ": "}
                              </div>
                              <div className="w-2/3">
                                {key === "standard"
                                  ? formData.admissionDetails.standard.split(
                                      ":"
                                    )[1]
                                  : key === "admissionDate"
                                  ? formatDate(
                                      formData.admissionDetails
                                        ?.admissionDate || ""
                                    )
                                  : formData.admissionDetails?.[key] || "N/A"}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </form>
                  </div>
                </div>
              }
            />
          </>
        ) : (
          <>
            <p className="text-red-500 mb-3">
              Fields marked with an * are mandatory
            </p>
            <form className="space-y-8">
              {/* Render fields dynamically */}
              {rows.map((row, rowIndex) => (
                <div key={rowIndex} className="grid gap-12 grid-cols-3">
                  {row.fields.map((fieldName) => {
                    const field = fields.find((f) => f.name === fieldName);
                    if (!field) return null;

                    return (
                      <div key={fieldName} className="flex flex-col relative">
                        <label className="font-admissionForm font-semibold text-gray-800 mb-2">
                          {field.label ? (
                            <>
                              {field.label}{" "}
                              {field.required && (
                                <span className="text-red-500">*</span>
                              )}
                            </>
                          ) : (
                            <br />
                          )}
                        </label>
                        {field.name === "standard" ? (
                          <select
                            name="standard"
                            value={selectedStandard}
                            onChange={handleStandardChange}
                            className="input text-sm font-admissionForm px-3 py-1 bg-white border-2 border-[#45b1a8b3] rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-[#45b1a8b3] w-36"
                          >
                            <option value=""></option>
                            {Object.keys(standardAndDivision).map((key) => (
                              <option key={key} value={key}>
                                {key.split(":")[1]}{" "}
                                {/* Displaying the standard label */}
                              </option>
                            ))}
                          </select>
                        ) : field.name === "division" ? (
                          <select
                            name="division"
                            value={selectedDivision}
                            onChange={handleDivisionChange}
                            className="input text-sm font-admissionForm px-3 py-1 bg-white border-2 border-[#45b1a8b3] rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-[#45b1a8b3] w-36"
                            disabled={!selectedStandard}
                          >
                            <option value=""></option>
                            {selectedStandard &&
                              standardAndDivision[selectedStandard]?.map(
                                (division: string) => (
                                  <option key={division} value={division}>
                                    {division}
                                  </option>
                                )
                              )}
                          </select>
                        ) : field.type === "select" ? (
                          <select
                            name={field.name}
                            value={formData[activeTabName]?.[field.name] || ""}
                            onChange={handleChange}
                            className="input text-sm font-admissionForm px-3 py-1 bg-white border-2 border-[#45b1a8b3] rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-[#45b1a8b3] w-36 truncate"
                          >
                            <option value=""></option>
                            {field.options?.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}

                            {(
                              selectOptions[
                                `STUDENT_${field.name.toUpperCase()}`
                              ] || []
                            ).map((option: any) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        ) : field.type === "date" ? (
                          <input
                            type={field.type}
                            name={field.name}
                            value={formData[activeTabName]?.[field.name] || ""}
                            onChange={handleChange}
                            className="input text-sm font-admissionForm px-3 py-1 bg-white border-2 border-[#45b1a8b3] rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-[#45b1a8b3] w-40"
                          />
                        ) : field.type === "file" ? (
                          <input
                            type="file"
                            name={field.name}
                            onChange={handleChange}
                            className="input text-sm font-admissionForm w-80 truncate"
                          />
                        ) : (
                          <input
                            type={field.type}
                            name={field.name}
                            value={formData[activeTabName]?.[field.name] || ""}
                            onChange={handleChange}
                            className={`input text-sm font-admissionForm px-3 py-1 bg-white border-2 border-[#45b1a8b3] rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-[#45b1a8b3] w-64 ${
                              field.name.includes("Address") && "w-80"
                            }`}
                            placeholder={field.placeholder}
                          />
                        )}

                        {/* Error message positioning */}
                        {errors[field.name] && (
                          <div className="absolute -bottom-6 text-red-500 text-sm mt-1">
                            <div className="flex items-center gap-2">
                              <FiAlertTriangle />
                              {errors[field.name]}.
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ))}
            </form>
          </>
        )}
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between items-center mt-6">
        <button
          className={`flex items-center font-semibold gap-x-2 px-7 py-2 rounded-3xl shadow-md ${
            activeTab === 0
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-blue-500 text-white hover:bg-blue-600"
          } transition-all`}
          onClick={handlePrevious}
          disabled={activeTab === 0}
        >
          <span>
            <IoIosArrowDropleft className="text-xl" />
          </span>
          Previous
        </button>

        {/* Conditionally render Submit or Next */}
        <button
          className={`flex items-center font-semibold gap-x-2 px-7 py-2 rounded-3xl shadow-md ${
            activeTab === tabTitles.length - 1
              ? "bg-green-600 text-white hover:bg-green-700"
              : "bg-green-600 text-white hover:bg-green-700"
          } transition-all`}
          onClick={() => {
            if (activeTab === tabTitles.length - 1) {
              handleConfirm(); // Handle form submission
            } else {
              handleNext();
            }
          }}
        >
          {activeTab === tabTitles.length - 1 ? (
            "Confirm"
          ) : (
            <>
              Next <IoIosArrowDropright className="text-xl" />
            </>
          )}
        </button>
      </div>

      {/* Confirm Popup Component */}
      <Popup
        isPopupOpen={showConfirmPopup}
        title="Form Submitted Successfully"
        content={
          <p className="px-6 font-semibold">
            Are you sure you want to submit the admission form?
          </p>
        }
        buttons={[
          {
            label: "Yes Admit",
            onClick: () => {
              setShowConfirmPopup(false);
              handleSubmit();
            },
            style: "bg-green-500 text-white hover:bg-green-600",
          },
          {
            label: "Check The Details",
            onClick: () => {
              setShowConfirmPopup(false);
            },
            style: "bg-blue-500 text-white hover:bg-blue-600",
          },
        ]}
        onClose={() => setShowConfirmPopup(false)}
      />

      {/* Popup Component */}
      <Popup
        isPopupOpen={showSubmitPopup}
        title="Form Submitted Successfully"
        content={
          <p className="px-6 font-semibold">
            Student Admission Sucessfully Completed! Student Id:{" "}
            <button
              className="text-blue-600 underline underline-offset-2"
              onClick={() => handleGoToStudentDetails()}
            >
              {sessionStorage.getItem("studentId")}
            </button>
          </p>
        }
        buttons={[
          {
            label: "Admit New Student",
            onClick: () => handleNewAdmit(),
            style: "bg-blue-500 text-white hover:bg-blue-600",
          },
        ]}
        onClose={() => handleNewAdmit()}
        buttonsGap="justify-center"
      />

      <Toast
        message={toastConfig.message}
        type={toastConfig.type}
        show={toastConfig.show}
        onClose={() => setToastConfig((prev) => ({ ...prev, show: false }))}
        duration={toastConfig.duration}
        position="top" // You can change this to 'top' if you prefer
      />
    </div>
  );
};

export default AdmissionForm;
