import React, { useEffect, useState } from "react";
import { useDropdown } from "../../hooks/useDropdown";
import { useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import { FaSearch } from "react-icons/fa";
import { TiArrowSortedDown } from "react-icons/ti";
import Toast from "./Toast";

// Update the component's props to accept a ref for the input element
interface HomeSearchProps {
  searchInputRef: React.RefObject<HTMLInputElement>;
}

const HomeSearch: React.FC<HomeSearchProps> = ({ searchInputRef }) => {
  const [selectedFilter, setSelectedFilter] = useState("Student");
  const [searchTerm, setSearchTerm] = useState("");

  const filterDropdown = useDropdown();

  const navigate = useNavigate();

  const FilterOptions = ["Student", "Staff", "Teacher", "Parent"];

  const [toastConfig, setToastConfig] = useState<{
    show: boolean;
    message: string;
    type: "error" | "success" | "info";
    duration: number;
  }>({
    show: false,
    message: "",
    type: "error",
    duration: 3000,
  });

  // Retrieve search term from sessionStorage when component mounts
  useEffect(() => {
    const storedSearchTerm = sessionStorage.getItem("searchTerm");
    if (storedSearchTerm) {
      setSearchTerm(storedSearchTerm); // Set the stored search term if available
    }
  }, []);

  // Save search term to sessionStorage whenever it changes
  useEffect(() => {
    if (searchTerm.trim()) {
      sessionStorage.setItem("searchTerm", searchTerm); // Store search term in sessionStorage
    }
  }, [searchTerm]);

  // Function to get the appropriate search hint message based on the selected filter
  const getSearchHintMessage = () => {
    switch (selectedFilter) {
      case "Student":
        return (
          <>
            <span className="underline font-bold">Student Id</span> or{" "}
            <span className="underline font-bold">Name</span>
          </>
        );
      case "Staff":
        return (
          <>
            <span className="underline font-bold">Staff Name</span>
          </>
        );
      case "Teacher":
        return (
          <>
            <span className="underline font-bold">Teacher Id</span> or{" "}
            <span className="underline font-bold">Name</span>
          </>
        );
      case "Parent":
        return (
          <>
            <span className="underline font-bold">Parent Name</span> or{" "}
            <span className="underline font-bold">Phone Number</span>
          </>
        );
      default:
        return ""; // Default message
    }
  };

  const handleSearch = () => {
    if (!searchTerm.trim()) {
      setToastConfig({
        show: true,
        message: "Please enter any text to search.",
        type: "error",
        duration: 3000,
      });
      return;
    }
    if (!selectedFilter) {
      setToastConfig({
        show: true,
        message: "Please select a filter to search.",
        type: "error",
        duration: 3000,
      });
      return;
    } else if (selectedFilter === "Student") {
      // Navigate with search term and filter type
      navigate(
        `/student_management/student_list/home_search_student?search=${searchTerm}&searchType=${selectedFilter.toUpperCase()}`
      );
    }
  };

  const clearSearchTerm = () => {
    setSearchTerm("");
    sessionStorage.removeItem("searchTerm");
  };

  return (
    <div className="flex flex-row items-center w-5/12">
      <div className="relative">
        <Tippy
          content="Select a filter"
          placement="bottom"
          className={`${
            filterDropdown.isDropdownOpen ? "invisible" : "visible"
          }`}
        >
          <button
            onClick={filterDropdown.toggleDropdown}
            className="bg-gray-600 w-28 py-1 px-3 text-white rounded-s-2xl focus:outline-none flex justify-between items-center gap-x-1 truncate"
          >
            <span className="truncate">
              {selectedFilter === "" ? "Filters" : selectedFilter}
            </span>
            <TiArrowSortedDown />
          </button>
        </Tippy>
        {filterDropdown.isDropdownOpen && (
          <div
            ref={filterDropdown.dropdownRef}
            className="absolute mt-2 w-40 bg-white shadow-lg rounded-2xl z-10"
          >
            {FilterOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectedFilter(option);
                  filterDropdown.closeDropdown();
                }}
                className={`p-2 cursor-pointer ${
                  selectedFilter === option && option !== ""
                    ? "bg-blue-500 text-white"
                    : "hover:bg-gray-200"
                }`}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="relative flex flex-row items-center flex-1">
        <div className="relative flex items-center w-1/2 flex-1">
          <input
            ref={searchInputRef}
            type="text"
            placeholder="Search anything..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSearch();
              }
            }}
            className="px-2 py-1 border flex-1 border-gray-300 rounded-e-2xl w-full xl:w-1/2 pr-10 focus:outline-none focus:ring-1 focus:border-blue-500" // Add padding for the icon space on the right
          />
          {/* Clear icon (×) visible when there's text in the input */}
          {searchTerm && (
            <button
              onClick={clearSearchTerm}
              className="absolute right-[15%] px-3 text-red-500 hover:text-red-600 font-bold text-xl transition transform duration-150 ease-in-out hover:scale-125"
            >
              &times;
            </button>
          )}
          <Tippy content="Click to search" placement="bottom">
            <button
              onClick={handleSearch}
              className="absolute w-[15%] h-[98%] bg-blue-600 flex justify-center items-center rounded-e-2xl right-0 top-1/2 transform -translate-y-1/2 text-white text-lg cursor-pointer transition duration-200 ease-in-out hover:shadow-inner hover:ring-1 hover:ring-blue-400 active:ring-2 active:scale-x-[0.95] active:scale-90"
            >
              <FaSearch />
            </button>
          </Tippy>
        </div>
        <div
          className={`absolute bottom-[-1.2rem] text-sm text-red-500 w-screen ${
            selectedFilter ? "visible" : "invisible"
          }`}
        >
          {selectedFilter !== "" && selectedFilter !== "Filters" && (
            <span>
              Selected filter is{" "}
              <span className="font-bold underline">{selectedFilter}</span>.
              Search with {getSearchHintMessage()}.
            </span>
          )}
        </div>
      </div>
      <Toast
        message={toastConfig.message}
        type={toastConfig.type}
        show={toastConfig.show}
        onClose={() => setToastConfig((prev) => ({ ...prev, show: false }))}
        duration={toastConfig.duration}
        position="top" // You can change this to 'top' if you prefer
      />
    </div>
  );
};
export default HomeSearch;
