import { useEffect, useState } from "react";
import { getFieldConfig } from "../../utils/studentAdmissionFieldConfig";
import { getRowConfig } from "../../utils/studentAdmissionRowConfig";
import { tabKeys } from "../../utils/studentAdmissionTabNames";

const useStudentAdmissionFieldLabels = (activeTabName: string) => {
  // Store the field configurations as { tabName: { name: label } }
  const [fieldConfigMap, setFieldConfigMap] = useState<any>({});

  const fields = getFieldConfig(activeTabName);

  const rows = getRowConfig(activeTabName);

  // Fetch and store only name and label for fields by tab
  useEffect(() => {
    const fetchFieldConfigs = async () => {
      const configMap: any = {};

      // Fetching field configuration for each tab (only name and label)
      tabKeys.forEach((tabKey) => {
        const fields = getFieldConfig(tabKey); // Get full field config for each tab
        const simplifiedFields = fields.reduce((acc: any, field: any) => {
          acc[field.name] = field.label; // Store name as key and label as value
          return acc;
        }, {});
        configMap[tabKey] = simplifiedFields;
      });

      setFieldConfigMap(configMap);
    };

    fetchFieldConfigs();
  }, []);

  return { fields, rows, fieldConfigMap };
};

export default useStudentAdmissionFieldLabels;
