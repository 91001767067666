import React, { useState } from "react";
import { FaFilePdf, FaFileExcel } from "react-icons/fa";
import { FaEllipsis } from "react-icons/fa6";
import { motion } from "framer-motion";
import { useDropdown } from "../../hooks/useDropdown";
import Toast from "./Toast";

// Interface for handling actions
interface TableOptionsMenuProps {
  selectedRows: any[]; // The data for selected rows
}

const TableOptionsMenu: React.FC<TableOptionsMenuProps> = ({
  selectedRows,
}) => {
  const [toastConfig, setToastConfig] = useState<{
    show: boolean;
    message: string;
    type: "error" | "success" | "info";
    duration: number;
  }>({
    show: false,
    message: "",
    type: "error",
    duration: 3000,
  });

  const tableMenuDropdown = useDropdown();

  // Temporarily log the selected rows to the console
  const handleExportPDF = () => {
    if (selectedRows.length === 0) {
      // If no rows are selected, show an alert
      setToastConfig({
        show: true,
        message: "No rows selected for PDF export.",
        type: "error",
        duration: 3000,
      });
      return;
    }

    // Log selected rows data in JSON format to the console
    console.log(
      "Selected rows data for PDF export:",
      JSON.stringify(selectedRows, null, 2)
    );
    setToastConfig({
      show: true,
      message: "Exported selected rows to PDF.",
      type: "success",
      duration: 3000,
    });
  };

  const handleExportExcel = () => {
    if (selectedRows.length === 0) {
      // If no rows are selected, show an alert
      setToastConfig({
        show: true,
        message: "No rows selected for Excel export.",
        type: "error",
        duration: 3000,
      });
      return;
    }

    // Log selected rows data in JSON format to the console
    console.log(
      "Selected rows data for Excel export:",
      JSON.stringify(selectedRows, null, 2)
    );
    setToastConfig({
      show: true,
      message: "Exported selected rows to Excel.",
      type: "success",
      duration: 3000,
    });
  };

  return (
    <div className="flex items-center">
      <div className="bg-slate-300 rounded-ss-md rounded-se-md">
        <div
          className="px-3 py-3 cursor-pointer"
          // onClick={() => setIsTableMenuOpen(!isTableMenuOpen)} // Toggle menu on click
          onClick={tableMenuDropdown.toggleDropdown}
        >
          <FaEllipsis />
        </div>

        {/* Dropdown menu with animation */}
        {tableMenuDropdown.isDropdownOpen && (
          <motion.div
            initial="closed"
            animate={tableMenuDropdown.isDropdownOpen ? "open" : "closed"}
            className="relative z-[1000]"
            ref={tableMenuDropdown.dropdownRef} // Attach the ref to the dropdown container
          >
            <motion.ul
              initial="closed"
              animate={tableMenuDropdown.isDropdownOpen ? "open" : "closed"}
              variants={menuVariants}
              className="flex flex-col p-2 rounded-lg bg-[#41c3b8] shadow-xl absolute top-[120%] left-[50%] w-44 overflow-hidden"
            >
              <MenuItem
                onClick={handleExportPDF}
                Icon={FaFilePdf}
                text="Export to PDF"
              />
              <MenuItem
                onClick={handleExportExcel}
                Icon={FaFileExcel}
                text="Export to Excel"
              />
            </motion.ul>
          </motion.div>
        )}
      </div>
      <Toast
        message={toastConfig.message}
        type={toastConfig.type}
        show={toastConfig.show}
        onClose={() => setToastConfig((prev) => ({ ...prev, show: false }))}
        duration={toastConfig.duration}
        position="top" // You can change this to 'top' if you prefer
      />
    </div>
  );
};

// Menu item component with prop types defined
interface MenuItemProps {
  onClick: () => void; // onClick function type
  Icon: React.ElementType; // Icon is a React component
  text: string; // text is a string
}

const MenuItem: React.FC<MenuItemProps> = ({ onClick, Icon, text }) => (
  <motion.li
    variants={itemVariants}
    onClick={onClick}
    className="flex items-center gap-2 w-full p-2 text-sm font-medium whitespace-nowrap  hover:bg-[#369f96] text-white  transition-colors cursor-pointer border-b border-white last:border-0"
  >
    <motion.span variants={actionIconVariants}>
      <Icon />
    </motion.span>
    <span className="text-sm">{text}</span>
  </motion.li>
);

export default TableOptionsMenu;

// Animation variants
const menuVariants = {
  open: {
    scaleY: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  closed: {
    scaleY: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
    },
  },
  closed: {
    opacity: 0,
    y: -15,
    transition: {
      when: "afterChildren",
    },
  },
};

const actionIconVariants = {
  open: { scale: 1, y: 0 },
  closed: { scale: 0, y: -7 },
};
