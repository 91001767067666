import React from "react";
import Sidebar from "../common/Sidebar";
import Header from "../common/Header";
import { useDashboard } from "../../contexts/DashboardContext";

type DashboardLayoutProps = React.PropsWithChildren<{}>;

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const { isSidebarOpen } = useDashboard();

  return (
    <div className="relative h-screen">
      <Header />
      <div className="absolute top-[64px] overflow-hidden w-full h-[calc(100vh-64px)] flex bg-gray-100">
        <Sidebar />
        <div
          className={`flex-1 relative overflow-auto transition-all ${
            isSidebarOpen ? "ml-[192px]" : "ml-0"
          }`}
        >
          <div className="absolute w-full h-full p-2 xl:px-4 xl:py-3 bg-gray-100 overflow-y-auto">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
