import React from "react";
import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";

interface PopupProps {
  isPopupOpen: boolean;
  title?: string;
  content: React.ReactNode;
  buttons: {
    label: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
    style?: string;
  }[];
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  divClassName?: string;
  buttonTopBorder?: boolean;
  buttonsGap?: string;
  headerBg?: string;
}

const Popup: React.FC<PopupProps> = ({
  isPopupOpen,
  title,
  content,
  buttons,
  onClose,
  divClassName,
  buttonTopBorder = true,
  buttonsGap = "justify-between",
  headerBg = "bg-gradient-to-r from-blue-200 to-purple-300",
}) => {
  // if (!isPopupOpen) return null;

  return createPortal(
    <AnimatePresence>
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center xl:pb-44 z-[9998]">
          {/* Background Blur with Animation */}
          <motion.div
            className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
            initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
            animate={{ opacity: 1, backdropFilter: "blur(0px)" }}
            exit={{ opacity: 0, backdropFilter: "blur(0px)" }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          />

          {/* Popup Animation */}
          <motion.div
            className={`rounded-lg shadow-lg relative bg-gradient-to-t from-blue-50 via-purple-100 to-pink-50 ${divClassName}`}
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div
              className={`flex justify-between rounded-ss-lg rounded-se-lg items-center px-6 py-3 border-b border-b-[#989898] ${headerBg}`}
            >
              {/* Title */}
              {title && (
                <h3 className="text-xl font-semibold  text-center">{title}</h3>
              )}

              {/* Close Button */}
              <button
                onClick={onClose}
                className=" hover:text-gray-600 text-2xl "
              >
                &times;
              </button>
            </div>

            {/* Content */}
            <div className="text-center min-h-24 flex items-center justify-center py-5 px-1">
              {content}
            </div>

            {/* Buttons */}
            <div
              className={`flex ${buttonsGap} gap-3 py-3 px-4 rounded-es-lg rounded-ee-lg ${
                buttonTopBorder && "border-t border-t-[#989898]"
              }`}
            >
              {buttons.map((button, index) => (
                <button
                  key={index}
                  onClick={button.onClick}
                  className={`px-4 py-2 rounded-md font-semibold ${
                    button.style || "bg-blue-500 text-white hover:bg-blue-600"
                  }`}
                >
                  {button.label}
                </button>
              ))}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default Popup;
