// AttendanceTab.tsx
import React from "react";
import FilterPanel from "../../common/FilterPanel";
import DataTable from "../../common/DataTable";

interface MonthlyAttendanceTabProps {
  filterOptions: any[];
  onFilterSubmit: () => void;
  visibleColumns: string[];
  data: any[];
  placeholder: string;
  onClearSubmit: () => void;
}

const MonthlyAttendanceTab: React.FC<MonthlyAttendanceTabProps> = ({
  filterOptions,
  onFilterSubmit,
  visibleColumns,
  data,
  placeholder,
  onClearSubmit,
}) => {
  return (
    <>
      {/* Filter Panel */}
      <FilterPanel
        heading="Monthly Attendance"
        filters={filterOptions}
        onFilterSubmit={onFilterSubmit}
        buttonText="Search"
        containerStyle="max-w-full"
        searchBarClassName="w-full"
        placeholder={placeholder}
        // searchTerm={searchTerm} // Pass search term to FilterPanel
        // onSearchChange={handleSearchChange} // Handle search term change
        onClearSubmit={onClearSubmit}
      />

      {/* DataTable for Add */}
      <div className="mt-3">
        <DataTable
          data={data}
          visibleColumns={visibleColumns}
          showCheckboxColumn={false}
          month="January"
          days={31}
          nonSortableColumns={[
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
          ]}
        />
      </div>
    </>
  );
};

export default MonthlyAttendanceTab;
