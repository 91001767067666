export const tabTitles = [
  "Student Info",
  "Parent Info",
  "Admission Details",
  "Additional Info",
  "Preview Form",
];
export const tabKeys = [
  "studentInfo",
  "parentInfo",
  "admissionDetails",
  "additionalInfo",
  "previewForm",
];
