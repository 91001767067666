import { useState, useEffect, useRef } from "react";

export const useScrollable = (data: any[], pageSizeOptions: number[]) => {
  const [isScrollable, setIsScrollable] = useState(false);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const checkIfScrollable = () => {
      if (tableContainerRef.current) {
        const container = tableContainerRef.current;
        setIsScrollable(container.scrollHeight > container.clientHeight);
      }
    };

    // Check on mount and whenever the data or pageSize changes
    checkIfScrollable();

    // Recheck when window is resized
    window.addEventListener("resize", checkIfScrollable);

    return () => {
      window.removeEventListener("resize", checkIfScrollable);
    };
  }, [data, pageSizeOptions]);

  return { isScrollable, tableContainerRef };
};
