import React from "react";

interface ProgressBarProps {
  activeTab: number;
  tabTitles: string[];
  // handleNext: () => void;
  // handlePrevious: () => void;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  activeTab,
  tabTitles,
  // handleNext,
  // handlePrevious,
}) => {
  // const handleTabClick = (index: number) => {
  //   if (index === activeTab) return; // Don't do anything if clicking on the active tab
  //   if (index > activeTab) {
  //     // Handle next functionality if clicking on a forward tab
  //     handleNext();
  //   } else {
  //     // Handle previous functionality if clicking on a backward tab
  //     handlePrevious();
  //   }
  // };

  return (
    <div className="mb-14">
      <div className="relative flex items-center">
        <div className="w-full h-2 bg-gray-300 rounded-full relative">
          <div
            className="absolute h-2 bg-green-500 rounded-full"
            style={{ width: `${(activeTab / (tabTitles.length - 1)) * 100}%` }}
          ></div>
        </div>
        <div className="absolute flex justify-between w-full top-[-15px]">
          {tabTitles.map((title, index) => (
            <div
              key={index}
              // onClick={() => handleTabClick(index)}
              className="flex flex-col items-center"
            >
              <div
                className={`h-8 w-8 rounded-full border-2 ${
                  activeTab > index
                    ? "bg-green-500 text-white border-green-500"
                    : "bg-white text-blue-600 border-blue-500 "
                } flex items-center justify-center text-sm font-semibold`}
              >
                {index + 1}
              </div>
              <span
                className={`text-sm mt-1 font-admissionForm font-semibold ${
                  index < activeTab ? "text-green-500" : "text-blue-500"
                }`}
              >
                {title}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
