// From YYYY-MM-DD to DD-MM-YYYY
export const formatDate = (date: string) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
  const year = d.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  if (formattedDate[0] === "0") return formattedDate.slice(1);

  return formattedDate;
};
