import React, { ReactNode, useState } from "react";
import DailyAttendanceTab from "../../components/features/attendance/DailyAttendanceTab";
import {
  studentVisibleColumnsAdd,
  studentVisibleColumnsRemove,
  teachingStaffVisibleColumnsAdd,
  teachingStaffVisibleColumnsRemove,
  nonTeachingStaffVisibleColumnsAdd,
  nonTeachingStaffVisibleColumnsRemove,
} from "../../utils/tableVisibleColumns";
import MonthlyAttendanceTab from "../../components/features/attendance/MonthlyAttendanceTab";
import { useStudentFilters } from "../../hooks/attendanceFilters/studentFilters";
import { useStudentMonthlyFilters } from "../../hooks/attendanceFilters/studentMonthlyFilters";
import {
  studentFilterOptions,
  studentMonthlyFilterOptions,
  teachingStaffFilterOptions,
  nonTeachingStaffFilterOptions,
  staffMonthlyFilterOptions,
} from "../../utils/filterOptions";
import TabDropdown from "../../components/common/TabDropdown";
import Toast from "../../components/common/Toast";
import { PiStudent } from "react-icons/pi";
import { GiTeacher } from "react-icons/gi";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { TiPlus } from "react-icons/ti";

type Student = {
  id: number;
  firstName: string;
  middleName: string;
  add?: JSX.Element;
};

const AttendancePage: React.FC = () => {
  const [teachingStaffFilters, setTeachingStaffFilters] = useState({
    subject: "",
  });

  const [nonTeachingStaffFilters, setNonTeachingStaffFilters] = useState({
    position: "",
  });

  const [staffMonthlyFilters, setStaffMonthlyFilters] = useState({
    position: "",
  });

  // Use custom hooks for filters
  const {
    studentFilters,
    standardAndSectionData,
    selectedStandard,
    filtersCleared,
    filteredData,
    toastConfig,
    searchTerm,
    filtersLoading,
    dataLoading,
    handleStudentFilterChange,
    handleStudentStandardChange,
    handleStudentFilterSubmit,
    handleStudentClearSubmit,
    handleSearchChange,
    setToastConfig,
  } = useStudentFilters();

  const {
    studentMonthlyFilters,
    standardAndSectionMonthlyData,
    selectedMonthlyStandard,
    handleStudentMonthlyFilterChange,
    handleStudentMonthlyStandardChange,
    handleStudentMonthlyFilterSubmit,
    handleStudentMonthlyClearSubmit,
  } = useStudentMonthlyFilters();

  const handleTeachingStaffFilterChange = (key: string, value: string) => {
    setTeachingStaffFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleNonTeachingStaffFilterChange = (key: string, value: string) => {
    setNonTeachingStaffFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleStaffMonthlyFilterChange = (key: string, value: string) => {
    setNonTeachingStaffFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleTeachingStaffFilterSubmit = () => {
    //logic
  };

  const handleNonTeachingStaffFilterSubmit = () => {
    //logic
  };

  const handleStaffMonthlyFilterSubmit = () => {
    //logic
  };

  const studentDailyData: ReactNode = (
    <div key="tab1-option1">
      <DailyAttendanceTab
        filterOptions={studentFilterOptions(
          standardAndSectionData,
          selectedStandard,
          studentFilters,
          handleStudentStandardChange,
          handleStudentFilterChange
        )}
        onFilterSubmit={handleStudentFilterSubmit}
        visibleColumnsAdd={studentVisibleColumnsAdd}
        visibleColumnsRemove={studentVisibleColumnsRemove}
        emptyColumnHeadings={["add", "remove"]}
        dataAdd={filteredData.map((student: Student) => ({
          ...student,
          add: <TiPlus size={19} />, // Add the 'add' key with the icon and text
        }))}
        onClearSubmit={handleStudentClearSubmit}
        filtersCleared={filtersCleared}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
      />
    </div>
  );

  const studentMonthlyData: ReactNode = (
    <div key="tab1-option2">
      <MonthlyAttendanceTab
        filterOptions={studentMonthlyFilterOptions(
          standardAndSectionMonthlyData,
          selectedMonthlyStandard,
          studentMonthlyFilters,
          handleStudentMonthlyStandardChange,
          handleStudentMonthlyFilterChange
        )}
        onFilterSubmit={handleStudentMonthlyFilterSubmit}
        onClearSubmit={handleStudentMonthlyClearSubmit}
        visibleColumns={["ID", "Name", "1", "2", "3", "4", "5"]}
        data={[
          {
            ID: 5,
            Name: "Kiran",
            "1": "✓",
            "2": "✓",
            "3": "✘",
            "4": "✓",
            "5": "✘",
          },
        ]}
        placeholder={"Search..."}
      />
    </div>
  );

  const teachingStaffDailyData: ReactNode = (
    <div key="tab2-option1">
      <DailyAttendanceTab
        filterOptions={teachingStaffFilterOptions(
          teachingStaffFilters,
          handleTeachingStaffFilterChange
        )}
        onFilterSubmit={() => {}}
        visibleColumnsAdd={teachingStaffVisibleColumnsAdd}
        visibleColumnsRemove={teachingStaffVisibleColumnsRemove}
        emptyColumnHeadings={["add", "remove"]}
        dataAdd={[
          {
            "Staff Id": 8,
            Name: "Kiran",
            Subject: "Maths",
            add: "Mark Absent",
          },
        ]}
        onClearSubmit={() => {}}
        filtersCleared={filtersCleared}
      />
    </div>
  );

  const nonTeachingStaffDailyData: ReactNode = (
    <div key="tab2-option2">
      <DailyAttendanceTab
        filterOptions={nonTeachingStaffFilterOptions(
          nonTeachingStaffFilters,
          handleNonTeachingStaffFilterChange
        )}
        onFilterSubmit={() => {}}
        visibleColumnsAdd={nonTeachingStaffVisibleColumnsAdd}
        visibleColumnsRemove={nonTeachingStaffVisibleColumnsRemove}
        emptyColumnHeadings={["add", "remove"]}
        dataAdd={[
          {
            "Staff Id": 10,
            Name: "Kiran",
            Position: "Security guard",
            add: "Mark Absent",
          },
        ]}
        onClearSubmit={() => {}}
        filtersCleared={filtersCleared}
      />
    </div>
  );

  const StaffMonthlyData: ReactNode = (
    <div key="tab2-option3">
      <MonthlyAttendanceTab
        filterOptions={staffMonthlyFilterOptions(
          staffMonthlyFilters,
          handleStaffMonthlyFilterChange
        )}
        onFilterSubmit={handleStaffMonthlyFilterSubmit}
        onClearSubmit={handleStudentMonthlyClearSubmit}
        visibleColumns={["ID", "Name", "1", "2", "3", "4", "5"]}
        data={[
          {
            ID: 5,
            Name: "Kiran",
            "1": "✓",
            "2": "✓",
            "3": "✘",
            "4": "✓",
            "5": "✘",
          },
        ]}
        placeholder={"Search..."}
      />
    </div>
  );

  const pageTabs = [
    {
      name: "Student Attendance",
      label: (
        <>
          <PiStudent size={20} /> Student Attendance
        </>
      ),
      options: ["Daily", "Monthly"],
      content: [studentDailyData, studentMonthlyData],
    },
    {
      name: "Staff Attendance",
      label: (
        <>
          <GiTeacher size={20} /> Staff Attendance
        </>
      ),
      options: ["Teaching", "Non-Teaching", "Monthly"],
      content: [
        teachingStaffDailyData,
        nonTeachingStaffDailyData,
        StaffMonthlyData,
      ],
    },
    {
      name: "add tabs",
      label: "add tabs",
      options: [],
      content: [""],
    },
  ];

  return (
    <>
      {filtersLoading ? (
        <LoadingSpinner message="Loading..." />
      ) : (
        <>
          {dataLoading && <LoadingSpinner bgBlur={true} />}
          <TabDropdown tabs={pageTabs} />
        </>
      )}

      <Toast
        message={toastConfig.message}
        type={toastConfig.type}
        show={toastConfig.show}
        onClose={() => setToastConfig((prev) => ({ ...prev, show: false }))}
        duration={toastConfig.duration}
        position="top"
      />
    </>
  );
};

export default AttendancePage;
