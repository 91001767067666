import React, { useEffect, useState } from "react";
import FilterPanel from "../../common/FilterPanel";
import DataTable from "../../common/DataTable";
import { MdDeleteForever } from "react-icons/md";
import { TiPlus, TiTick } from "react-icons/ti";
import Toast from "../../common/Toast";
import Accordion from "../../common/Accordion";
import Tippy from "@tippyjs/react";
import AbsentSummaryTable from "./AbsentSummaryTable";
import { LuPencil } from "react-icons/lu";
import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

interface DailyAttendanceTabProps {
  filterOptions: any[];
  onFilterSubmit: () => void;
  visibleColumnsAdd: string[];
  visibleColumnsRemove: string[];
  dataAdd: any[];
  onClearSubmit: () => void;
  emptyColumnHeadings: string[];
  filtersCleared: boolean;
  searchTerm?: string;
  handleSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DailyAttendanceTab: React.FC<DailyAttendanceTabProps> = ({
  filterOptions,
  onFilterSubmit,
  visibleColumnsAdd,
  visibleColumnsRemove,
  dataAdd,
  onClearSubmit,
  emptyColumnHeadings,
  filtersCleared,
  searchTerm,
  handleSearchChange,
}) => {
  const [addedData, setAddedData] = useState<any[]>([]);
  const [absentData, setAbsentData] = useState<any[]>([]);
  const [reasons, setReasons] = useState<{ [key: string]: string }>({});
  const [reasonError, setReasonError] = useState<{ [key: string]: boolean }>(
    {}
  ); // Track if reason is empty
  const [editingReasonId, setEditingReasonId] = useState<string | null>(null); // Track which student's reason is being edited
  const [editedReason, setEditedReason] = useState<string>(""); // Track the new value of the reason

  const [toastConfig, setToastConfig] = useState<{
    show: boolean;
    message: string;
    type: "error" | "success" | "info";
    duration: number;
  }>({
    show: false,
    message: "",
    type: "error",
    duration: 3000,
  });

  const standards = [
    "1st Standard",
    "2nd Standard",
    "3rd Standard",
    "4th Standard",
    "5th Standard",
    "6th Standard",
    "7th Standard",
    "8th Standard",
    "9th Standard",
    "10th Standard",
  ];

  // Update addedData when dataAdd changes and check for already absent students
  useEffect(() => {
    const updatedData = dataAdd.map((student) => {
      const isAbsent = absentData.some((absent) => absent.id === student.id);
      return {
        ...student,
        add: (
          <Tippy
            content={
              isAbsent ? "Selected to absent list" : "Add to absent list"
            }
            placement="bottom"
            theme={isAbsent ? "light" : "translucent"}
          >
            <span
              className={`flex justify-center items-center gap-x-1 p-1 rounded-3xl transition ease-in-out duration-300 ${
                isAbsent
                  ? "bg-yellow-400 hover:bg-yellow-500"
                  : "bg-green-400 hover:bg-green-500"
              }`}
            >
              {isAbsent ? <TiTick size={19} /> : <TiPlus size={19} />}
            </span>
          </Tippy>
        ),
      };
    });
    setAddedData(updatedData);
  }, [dataAdd, absentData]);

  const handleCellClick = (columnKey: string, row: any) => {
    if (columnKey === "add") {
      // Check if the student is already in the absentData list
      const isAlreadyAdded = absentData.some((item) => item.id === row.id);

      // If the student is already marked as absent
      if (isAlreadyAdded) {
        setToastConfig({
          show: true,
          message: `${row.firstName} ${row.middleName} is already marked as absent.`,
          type: "error",
          duration: 3000,
        });
        return; // Stop further processing if already added
      }

      const reasonForAbsent = reasons[row.id]; // Get reason for absence

      if (!reasonForAbsent || reasonForAbsent.trim() === "") {
        setReasonError((prev) => ({ ...prev, [row.id]: true }));
        setToastConfig({
          show: true,
          message: "Please enter a reason for absence before adding.",
          type: "error",
          duration: 3000,
        });
        return;
      }

      // Add the student to the absent list (absentData)
      setAbsentData((prev) => [
        ...prev,
        {
          ...row,
          removeReasonForAbsent: reasonForAbsent,
          remove: <MdDeleteForever size={21} />,
        },
      ]);

      // Change the "add" button to "tick"
      setAddedData((prev) =>
        prev.map((student) =>
          student.id === row.id
            ? {
                ...student,
                add: <TiTick size={19} />,
              }
            : student
        )
      );
    }

    if (columnKey === "remove") {
      // Remove the student from the absentData array
      setAbsentData((prev) => prev.filter((item) => item.id !== row.id));

      // Remove the reason for absence from the reasons state
      setReasons((prev) => {
        const updatedReasons = { ...prev };
        delete updatedReasons[row.id]; // Remove the reason for this student
        return updatedReasons;
      });

      setAddedData((prev) =>
        prev.map((student) =>
          student.id === row.id
            ? { ...student, add: <TiPlus size={19} /> }
            : student
        )
      );
    }
  };

  const handleReasonChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const value = e.target.value;
    setReasons((prev) => ({
      ...prev,
      [id]: value,
    }));

    // Remove error state if the reason is now filled
    if (value.trim() !== "") {
      setReasonError((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleEditClick = (id: string) => {
    setEditingReasonId(id);
    setEditedReason(reasons[id]); // Load the existing reason for editing

    // Focus the input immediately after setting the editingReasonId
    setTimeout(() => {
      const inputElement = document.getElementById(`reason-input-${id}`);
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }, 0);
  };

  const handleSaveClick = (id: string) => {
    if (editedReason.trim() === "") {
      setReasonError((prev) => ({ ...prev, [id]: true }));
      setToastConfig({
        show: true,
        message: "Reason should not be empty.",
        type: "error",
        duration: 3000,
      });
      return; // Don't save if reason is empty
    }

    setReasons((prev) => ({
      ...prev,
      [id]: editedReason,
    }));
    setEditingReasonId(null); // Exit edit mode
  };

  const handleCancelClick = () => {
    setEditingReasonId(null); // Exit edit mode without saving
  };

  return (
    <>
      {/* Filter Panel */}
      <FilterPanel
        heading="Daily Attendance"
        filters={filterOptions}
        onFilterSubmit={onFilterSubmit}
        buttonText="Search"
        containerStyle="max-w-full"
        searchBarClassName="w-full"
        placeholder="Student name..."
        searchTerm={searchTerm} // Pass search term to FilterPanel
        onSearchChange={handleSearchChange} // Handle search term change
        onClearSubmit={onClearSubmit}
      />

      {/* DataTable for Add */}
      <div className="mt-3">
        <DataTable
          data={addedData.map((row) => ({
            ...row,
            addReasonForAbsent: (
              <div
                className={` ${
                  reasonError[row.id]
                    ? "border-[3px] border-red-500"
                    : "border-2 border-gray-400"
                }`}
              >
                <input
                  type="text"
                  value={reasons[row.id] || ""}
                  onChange={(e) => handleReasonChange(e, row.id)}
                  className={`w-full focus:outline-none px-2 py-1 h-full`}
                  placeholder="Enter reason"
                />
              </div>
            ),
          }))}
          visibleColumns={visibleColumnsAdd}
          nonSortableColumns={["add", "addReasonForAbsent"]}
          clickableColumns={["add"]}
          emptyColumnHeadings={emptyColumnHeadings}
          filtersCleared={filtersCleared}
          customRenderedColumns={["add", "addReasonForAbsent"]}
          showRowHover={false}
          flexColumns={["add"]}
          onCellClick={handleCellClick}
          showCheckboxColumn={false}
        />
      </div>

      {/* Accordion for Absentees Summary */}
      <Accordion
        title="Absentees Summary"
        content={
          <AbsentSummaryTable absentData={absentData} standards={standards} />
        }
      />

      {/* DataTable for Remove */}
      <div className="mt-3">
        <DataTable
          data={absentData.map((row) => ({
            ...row,
            removeReasonForAbsent: (
              <div>
                {editingReasonId === row.id ? (
                  <div className="flex gap-x-2">
                    <input
                      id={`reason-input-${row.id}`} // Add unique ID for each input
                      type="text"
                      value={editedReason}
                      onChange={(e) => setEditedReason(e.target.value)}
                      className={`w-full focus:outline-none px-2 py-1 h-full ${
                        reasonError[row.id]
                          ? "border-[3px] border-red-500"
                          : "border-2 border-gray-400"
                      }`}
                      placeholder="Enter reason"
                    />
                    <button onClick={() => handleSaveClick(row.id)}>
                      <FaCheck size={18} color="green" />
                    </button>
                    <button className="ml-2" onClick={handleCancelClick}>
                      <IoClose size={24} color="red" />
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-between">
                    <span>{reasons[row.id] || "No reason provided"}</span>

                    <Tippy
                      content={"Edit reason"}
                      placement="bottom"
                      className="z-[99999]"
                    >
                      <LuPencil
                        size={18}
                        className="cursor-pointer"
                        onClick={() => handleEditClick(row.id)}
                      />
                    </Tippy>
                  </div>
                )}
                {/* </motion.div> */}
              </div>
            ),
          }))}
          visibleColumns={visibleColumnsRemove}
          nonSortableColumns={["removeReasonForAbsent", "remove"]}
          tableName="Absent List"
          showCheckboxColumn={false}
          clickableColumns={["removeReasonForAbsent", "remove"]}
          emptyColumnHeadings={emptyColumnHeadings}
          customRenderedColumns={["removeReasonForAbsent", "remove"]}
          showRowHover={false}
          flexColumns={["remove"]}
          defaultPageSize={20}
          onCellClick={handleCellClick}
        />
      </div>

      <div className="flex justify-center mt-3">
        <button className="bg-emerald-400 hover:bg-emerald-500 text-white rounded-3xl py-3 px-5">
          Submit Attendance
        </button>
      </div>

      <Toast
        message={toastConfig.message}
        type={toastConfig.type}
        show={toastConfig.show}
        onClose={() => setToastConfig((prev) => ({ ...prev, show: false }))}
        duration={toastConfig.duration}
        position="top"
      />
    </>
  );
};

export default DailyAttendanceTab;
