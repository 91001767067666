import React, { ReactNode } from "react";
import { RiArrowLeftSFill, RiArrowRightSFill } from "react-icons/ri";
import { BsChevronBarLeft, BsChevronBarRight } from "react-icons/bs";
import Tippy from "@tippyjs/react";

interface PaginationProps {
  currentPage: number;
  totalEntries: number;
  onPageChange: (page: number) => void;
  pageSizeOptions: number[];
  onPageSizeChange: (size: number) => void;
  tableFooter: ReactNode;
  pageSize: number;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalEntries,
  onPageChange,
  pageSizeOptions,
  onPageSizeChange,
  tableFooter,
  pageSize,
}) => {
  const totalPages = Math.ceil(totalEntries / pageSize);

  const handlePageSizeChange = (size: number) => {
    onPageSizeChange(size); // Notify parent (DataTable) about the page size change
  };

  return (
    <div className="flex justify-end gap-x-11 mt-4 items-center">
      <div className="flex-1 flex justify-center">{tableFooter}</div>

      {/* Page size selector and options */}
      <div className="flex items-center gap-4 mt-0">
        Rows per page
        <select
          className="border p-2 rounded"
          value={pageSize}
          onChange={(e) => handlePageSizeChange(Number(e.target.value))}
        >
          {pageSizeOptions.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>

      <span>
        {Math.min((currentPage - 1) * pageSize + 1, totalEntries)} -{" "}
        {Math.min(currentPage * pageSize, totalEntries)} of {totalEntries}
      </span>

      {/* Pagination controls */}
      <div className="flex items-center gap-x-4">
        <Tippy content="Go to start page" placement="bottom">
          <div>
            <button
              className="disabled:opacity-50"
              onClick={() => onPageChange(1)}
              disabled={currentPage === 1}
            >
              <BsChevronBarLeft size={19} />
            </button>
          </div>
        </Tippy>
        <Tippy content="Go to previous page" placement="bottom">
          <div>
            <button
              className="disabled:opacity-50"
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <RiArrowLeftSFill size={26} />
            </button>{" "}
          </div>
        </Tippy>
        <Tippy content="Go to next page" placement="bottom">
          <div>
            <button
              className="disabled:opacity-50"
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <RiArrowRightSFill size={26} />
            </button>
          </div>
        </Tippy>
        <Tippy content="Go to end page" placement="bottom">
          <div>
            <button
              className="disabled:opacity-50"
              onClick={() => onPageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              <BsChevronBarRight size={19} />
            </button>
          </div>
        </Tippy>
      </div>
    </div>
  );
};

export default Pagination;
