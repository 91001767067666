export const studentFilterOptions = (
  standardAndSectionData: any,
  selectedStandard: string,
  studentFilters: any,
  handleStudentStandardChange: any,
  handleStudentFilterChange: any
) => [
  {
    label: "Standard",
    type: "select" as const,
    options: Object.keys(standardAndSectionData || {}).map(
      (key) => key.split(":")[1]
    ),
    value: studentFilters.standard,
    onChange: handleStudentStandardChange,
    mandatory: true,
  },
  {
    label: "Section",
    type: "select" as const,
    options: studentFilters.standard
      ? standardAndSectionData?.[selectedStandard] || []
      : [],
    value: studentFilters.section,
    onChange: (value: string) => handleStudentFilterChange("section", value),
  },
];

export const studentMonthlyFilterOptions = (
  standardAndSectionMonthlyData: any,
  selectedMonthlyStandard: string,
  studentMonthlyFilters: any,
  handleStudentMonthlyStandardChange: any,
  handleStudentMonthlyFilterChange: any
) => [
  {
    label: "Month",
    type: "select",
    options: [
      "January",
      "February",
      "March",
      "April",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    value: studentMonthlyFilters.month,
    onChange: (value: string) =>
      handleStudentMonthlyFilterChange("month", value),
  },
  {
    label: "Standard",
    type: "select" as const,
    options: Object.keys(standardAndSectionMonthlyData || {}).map(
      (key) => key.split(":")[1]
    ),
    value: studentMonthlyFilters.standard,
    onChange: handleStudentMonthlyStandardChange,
    mandatory: true,
  },
  {
    label: "Section",
    type: "select" as const,
    options: studentMonthlyFilters.standard
      ? standardAndSectionMonthlyData?.[selectedMonthlyStandard] || []
      : [],
    value: studentMonthlyFilters.section,
    onChange: (value: string) =>
      handleStudentMonthlyFilterChange("section", value),
  },
];

export const teachingStaffFilterOptions = (
  teachingStaffFilters: any,
  handleTeachingStaffFilterChange: any
) => [
  {
    label: "Subject",
    type: "select",
    options: ["Maths", "Science", "Social", "English"],
    value: teachingStaffFilters.subject,
    onChange: (value: string) =>
      handleTeachingStaffFilterChange("subject", value),
  },
];

export const nonTeachingStaffFilterOptions = (
  nonTeachingStaffFilters: any,
  handleNonTeachingStaffFilterChange: any
) => [
  {
    label: "Position",
    type: "select",
    options: ["Security guard"],
    value: nonTeachingStaffFilters.position,
    onChange: (value: string) =>
      handleNonTeachingStaffFilterChange("position", value),
  },
];

export const staffMonthlyFilterOptions = (
  staffMonthlyFilters: any,
  handleStaffMonthlyFilterChange: any
) => [
  {
    label: "Position",
    type: "select",
    options: ["Security guard", "Teaching"],
    value: staffMonthlyFilters.position,
    onChange: (value: string) =>
      handleStaffMonthlyFilterChange("position", value),
  },
];
