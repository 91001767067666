import React from "react";

interface SearchBarProps {
  placeholder?: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  customStyling?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = "Search...",
  value,
  onChange,
  customStyling = "",
}) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange ? onChange : () => {}} // Provide a no-op function if onChange is undefined
      className={`p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${customStyling}`}
    />
  );
};

export default SearchBar;
