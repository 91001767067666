import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreateTenant: React.FC = () => {
  const [tenantName, setTenantName] = useState("");
  const [internalCode, setInternalCode] = useState("");
  const [additionalFields, setAdditionalFields] = useState("");
  const [field4, setField4] = useState("");
  const [field5, setField5] = useState("");
  const [field6, setField6] = useState("");
  const [field7, setField7] = useState("");
  const [field8, setField8] = useState("");
  const [field9, setField9] = useState("");
  const [field10, setField10] = useState("");

  const navigate = useNavigate();

  const handleSave = () => {
    // Logic to save the tenant (e.g., API call)
    console.log("Tenant Saved:", {
      tenantName,
      internalCode,
      additionalFields,
      field4,
      field5,
      field6,
      field7,
      field8,
      field9,
      field10,
    });
    navigate("/master_setup/tenant_setup", {
      state: { fromCreateTenant: true },
    }); // Redirect back to the tenant setup page
  };

  const handleCancel = () => {
    navigate("/master_setup/tenant_setup", {
      state: { fromCreateTenant: true },
    }); // Redirect back to the tenant setup page without saving
  };

  return (
    <div className="bg-white p-6 rounded-md shadow-md max-w-lg mx-auto">
      <h2 className="text-2xl font-semibold mb-6">Create New Tenant</h2>
      <form>
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Tenant Name
              </label>
              <input
                type="text"
                value={tenantName}
                onChange={(e) => setTenantName(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Internal Code
              </label>
              <input
                type="text"
                value={internalCode}
                onChange={(e) => setInternalCode(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Additional Fields
              </label>
              <input
                type="text"
                value={additionalFields}
                onChange={(e) => setAdditionalFields(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Field 4
              </label>
              <input
                type="text"
                value={field4}
                onChange={(e) => setField4(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Field 5
              </label>
              <input
                type="text"
                value={field5}
                onChange={(e) => setField5(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Field 6
              </label>
              <input
                type="text"
                value={field6}
                onChange={(e) => setField6(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Field 7
              </label>
              <input
                type="text"
                value={field7}
                onChange={(e) => setField7(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Field 8
              </label>
              <input
                type="text"
                value={field8}
                onChange={(e) => setField8(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Field 9
              </label>
              <input
                type="text"
                value={field9}
                onChange={(e) => setField9(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Field 10
              </label>
              <input
                type="text"
                value={field10}
                onChange={(e) => setField10(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
        <div className="mt-6 flex justify-end gap-4">
          <button
            type="button"
            onClick={handleSave}
            className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Save
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="px-6 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateTenant;
