// home search columns
export const homeSearchVisibleColumns = [
  "id",
  "fullName",
  "standard",
  "division",
  "gender",
  "phoneNumber",
];

// student list columns
export const studentListvisibleColumns = [
  "id",
  "fullName",
  "standard",
  "division",
  "gender",
  "phoneNumber",
];

// student attendance columns
export const studentVisibleColumnsAdd = [
  "add",
  "addReasonForAbsent",
  "id",
  "fullName",
  "standard",
  "division",
];
export const studentVisibleColumnsRemove = [
  "id",
  "fullName",
  "standard",
  "division",
  "removeReasonForAbsent",
  "remove",
];

// staff attendance columns
export const teachingStaffVisibleColumnsAdd = [
  "add",
  "addReasonForAbsent",
  "Staff Id",
  "Name",
  "Subject",
];

export const teachingStaffVisibleColumnsRemove = [
  "Staff Id",
  "Name",
  "Subject",
  "removeReasonForAbsent",
  "remove",
];

export const nonTeachingStaffVisibleColumnsAdd = [
  "add",
  "addReasonForAbsent",
  "Staff Id",
  "Name",
  "Position",
];

export const nonTeachingStaffVisibleColumnsRemove = [
  "Staff Id",
  "Name",
  "Position",
  "removeReasonForAbsent",
  "remove",
];

// tenant user details columns
export const tenantUsersVisibleColumns = [
  "userName",
  "password",
  "status",
  "accountLocked",
  "accountActive",
  "invalidLoginAttempt",
];
