import React from "react";
import AdmissionForm from "../../components/features/admission/AdmissionForm";

const StudentAdmissioin: React.FC = () => {
  return (
    <div className="px-4">
      <AdmissionForm />
    </div>
  );
};

export default StudentAdmissioin;
