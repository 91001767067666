import React from "react";

interface AbsentSummaryTableProps {
  absentData: any[];
  standards: string[]; // New prop to accept standards
}

const AbsentSummaryTable: React.FC<AbsentSummaryTableProps> = ({
  absentData,
  standards,
}) => {
  // Group absentees by standard
  const absenteesByStandard = absentData.reduce(
    (acc: { [key: string]: number }, student) => {
      const standard = student.standard; // assuming 'standard' is the property
      if (!acc[standard]) {
        acc[standard] = 0;
      }
      acc[standard] += 1;
      return acc;
    },
    {}
  );

  // Calculate the total number of absentees
  const totalAbsentees = Object.values(absenteesByStandard).reduce(
    (total: number, count: number) => total + count,
    0
  );

  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full border-collapse">
        <thead>
          <tr>
            <th className="border border-gray-400 px-4 py-2 text-left text-lg text-teal-600 ">
              Standard
            </th>
            <th className="border border-gray-400 px-4 py-2 text-left text-lg text-teal-600 ">
              No. of Absentees
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Map over the standards array */}
          {standards.map((standard) => {
            // Get absentee count for this standard, default to 0 if not found
            const absenteeCount = absenteesByStandard[standard] || 0;

            return (
              <tr key={standard}>
                <td className="border border-gray-400 px-4 py-2">{standard}</td>
                <td className="border border-gray-400 px-4 py-2">
                  {absenteeCount}
                </td>
              </tr>
            );
          })}
          <tr>
            <td className="border border-gray-400 px-4 py-2 font-bold text-lg text-red-700">
              Total
            </td>
            <td className="border border-gray-400 px-4 py-2 font-bold text-lg text-red-700">
              {totalAbsentees}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AbsentSummaryTable;
