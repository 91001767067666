import { useState, useEffect } from "react";
import { getUpdatedUserDetails, getUsersByTenant } from "../../../services/api";
import LoadingSpinner from "../../common/LoadingSpinner";
import CustomTable from "../../common/CustomTable";
import { MdModeEdit } from "react-icons/md";
import Tabs from "../../common/Tabs";
import Popup from "../../common/Popup";
import Toast from "../../common/Toast";

type TenantTabsDataProps = {
  tenantId: number | undefined;
};

const TenantTabsData: React.FC<TenantTabsDataProps> = ({ tenantId }) => {
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editingUser, setEditingUser] = useState<any | null>(null);
  const [toastConfig, setToastConfig] = useState<{
    show: boolean;
    message: string;
    type: "error" | "success" | "info";
    duration: number;
  }>({
    show: false,
    message: "",
    type: "error",
    duration: 3000,
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    if (tenantId !== undefined) {
      try {
        const response = await getUsersByTenant({ tenantId });
        setUsers(response); // Assuming the response structure matches your data format
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false); // No tenantId found, stop loading
    }
  };

  const visibleColumns = [
    "userName",
    "password",
    "status",
    "accountLocked",
    "accountActive",
    "invalidLoginAttempt",
    "edit",
  ];

  const nonSortableColumns = [
    "password",
    "status",
    "accountLocked",
    "accountActive",
    "invalidLoginAttempt",
    "edit",
  ];

  const columnHeaders = {
    userName: "User Name",
    status: "Status",
    accountLocked: "Account Locked",
    accountActive: "Account Active",
    password: "Password",
    invalidLoginAttempt: "Invalid Login Attempt",
    edit: " ",
  };

  const handleUserEdit = (user: any) => {
    setEditingUser(user); // Set the user data that will be edited
    setIsPopupOpen(true); // Open the popup
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false); // Close the popup
    setEditingUser(null); // Clear the editing user data
  };

  const handleSaveChanges = async (updatedUser: any) => {
    if (!updatedUser) return;

    // Prepare the parameters to send to the API
    const params = {
      secUserId: updatedUser.secUserId, // Get secUserId from the user object
      password: updatedUser.password, // Pass updated password
      accountLocked: updatedUser.accountLocked || "N", // Default to "N" if empty
      accountActive: updatedUser.accountActive || "N", // Default to "N" if empty
      invalidLoginAttempt: updatedUser.invalidLoginAttempt.toString(), // Convert to string
    };

    try {
      // Call the API to update user details
      const response = await getUpdatedUserDetails(params);

      if (response) {
        setToastConfig({
          show: true,
          message: "User details updated successfully!",
          type: "success",
          duration: 3000,
        });
      } else {
        setToastConfig({
          show: true,
          message: "Failed to update user details. Please try again.",
          type: "error",
          duration: 3000,
        });
      }
    } catch (error) {
      console.log("Error occurred while updating user details.");
    } finally {
      setIsPopupOpen(false); // Close the popup after the operation
    }

    fetchUsers();
  };

  const renderCell = (row: any, column: string) => {
    if (column === "edit") {
      return (
        <button
          onClick={() => handleUserEdit(row)}
          className="flex items-center gap-x-2 px-4 py-1 rounded-3xl bg-red-400 text-white hover:bg-red-500"
        >
          Edit <MdModeEdit size={20} />
        </button>
      );
    }
    return row[column] || "-"; // Default to "-" if no data
  };

  const tabsData = [
    {
      name: "Users",
      content: loading ? (
        <LoadingSpinner fullScreen={false} />
      ) : (
        <>
          <CustomTable
            data={users}
            visibleColumns={visibleColumns}
            nonSortableColumns={nonSortableColumns}
            columnHeaders={columnHeaders}
            renderCell={renderCell} // Pass the renderCell function to customize cell content
          />
        </>
      ),
    },
    {
      name: "Tab 2",
      content: "Tab 2 content",
    },
    {
      name: "Tab 3",
      content: "Tab 3 content",
    },
    {
      name: "Tab 4",
      content: "Tab 4 content",
    },
  ];

  return (
    <div className="mt-3">
      <Tabs
        tabsData={tabsData}
        activeTabColor="#96CDFF"
        inactiveTabColor="#96CDFF"
        activeTabTextColor="#66b3fa"
        hoverTabColor="#66b3fa"
        hoverTextColor="#fff"
        customContentClass="bg-white"
        buttonBorder="border-l-2 border-white"
      />

      <Popup
        isPopupOpen={isPopupOpen}
        title="Edit User"
        divClassName="bg-[#f8edeb] w-2/5"
        content={
          <div className="px-5 w-full">
            <form className="space-y-4">
              <div className="flex flex-col gap-y-4">
                {editingUser && (
                  <>
                    <div className="flex flex-row gap-4 items-center">
                      <label className="font-semibold w-1/3 text-left">
                        User Name:
                      </label>
                      <input
                        type="text"
                        value={editingUser.userName}
                        className="border px-2 py-1 rounded-lg w-2/3 bg-gray-300"
                        disabled
                      />
                    </div>
                    <div className="flex flex-row gap-4 items-center">
                      <label className="font-semibold w-1/3 text-left">
                        Password:
                      </label>
                      <input
                        type="text"
                        value={editingUser.password || ""}
                        onChange={(e) =>
                          setEditingUser({
                            ...editingUser,
                            password: e.target.value,
                          })
                        }
                        className="border px-2 py-1 rounded-lg w-2/3"
                      />
                    </div>
                    <div className="flex flex-row gap-4 items-center">
                      <label className="font-semibold w-1/3 text-left">
                        Status:
                      </label>
                      <input
                        type="text"
                        value={editingUser.status}
                        className="border px-2 py-1 rounded-lg w-2/3 bg-gray-300"
                        disabled
                      />
                    </div>
                    <div className="flex flex-row gap-4 items-center">
                      <label className="font-semibold w-1/3 text-left">
                        Account Locked:
                      </label>
                      <input
                        type="text"
                        value={editingUser.accountLocked || ""}
                        onChange={(e) =>
                          setEditingUser({
                            ...editingUser,
                            accountLocked: e.target.value || "",
                          })
                        }
                        className="border px-2 py-1 rounded-lg w-2/3"
                      />
                    </div>
                    <div className="flex flex-row gap-4 items-center">
                      <label className="font-semibold w-1/3 text-left">
                        Account Active:
                      </label>
                      <input
                        type="text"
                        value={editingUser.accountActive || ""}
                        onChange={(e) =>
                          setEditingUser({
                            ...editingUser,
                            accountActive: e.target.value || "",
                          })
                        }
                        className="border px-2 py-1 rounded-lg w-2/3"
                      />
                    </div>
                    <div className="flex flex-row gap-4 items-center">
                      <label className="font-semibold w-1/3 text-left">
                        Invalid Login Attempt:
                      </label>
                      <input
                        type="number"
                        value={editingUser.invalidLoginAttempt || 0}
                        onChange={(e) =>
                          setEditingUser({
                            ...editingUser,
                            invalidLoginAttempt: parseInt(e.target.value, 10),
                          })
                        }
                        className="border px-2 py-1 rounded-lg w-2/3"
                      />
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        }
        buttons={[
          {
            label: "Save",
            onClick: () => handleSaveChanges(editingUser),
            style: "bg-blue-500 text-white hover:bg-blue-600",
          },
          {
            label: "Cancel",
            onClick: handleClosePopup,
            style: "bg-gray-300 text-gray-700 hover:bg-gray-400",
          },
        ]}
        onClose={handleClosePopup}
        buttonTopBorder={false}
        buttonsGap="justify-end"
      />

      <Toast
        message={toastConfig.message}
        type={toastConfig.type}
        show={toastConfig.show}
        onClose={() => setToastConfig((prev) => ({ ...prev, show: false }))}
        duration={toastConfig.duration}
        position="top" // You can change this to 'top' if you prefer
      />
    </div>
  );
};

export default TenantTabsData;
