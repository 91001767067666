import { useState, useMemo } from "react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa6";

interface SortConfig {
  key: string | null;
  direction: "ascending" | "descending" | "none" | null;
}

const useSorting = (data: any[], nonSortableColumns: string[]) => {
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: null,
    direction: "none",
  });

  // Track the original data to reset when necessary
  // const originalData = useMemo(() => [...data], [data]);

  // Track the original data to reset when necessary, ensuring it's an array
  const originalData = useMemo(() => {
    // Handle invalid data by returning an empty array
    if (!Array.isArray(data)) {
      console.error("Invalid data provided. Expected an array but got:", data);
      return [];
    }
    return [...data];
  }, [data]);

  const handleSort = (key: string) => {
    if (nonSortableColumns.includes(key)) return; // Skip sorting if column is in nonSortableColumns

    let direction: "ascending" | "descending" | "none" = "ascending"; // default to ascending

    // Handle the sorting logic based on the current sort state
    if (sortConfig.key === key) {
      if (sortConfig.direction === "ascending") {
        direction = "descending"; // Toggle to descending
      } else if (sortConfig.direction === "descending") {
        direction = "none"; // Reset to original order (no sorting)
      } else {
        direction = "ascending"; // Default to ascending if no sort was applied
      }
    } else {
      direction = "ascending"; // Set default to ascending for new column
    }

    setSortConfig({ key, direction });
  };

  const getSortIcon = (key: string) => {
    if (sortConfig.key !== key) return <FaSort className="text-[#a4c3b2]" />;

    if (sortConfig.direction === "ascending")
      return <FaSortUp className="text-[#f72585]" />;
    if (sortConfig.direction === "descending")
      return <FaSortDown className="text-[#f72585]" />;
    return <FaSort className="text-[#a4c3b2]" />;
  };

  const sortedData = useMemo(() => {
    if (sortConfig.key === null || sortConfig.direction === "none") {
      return originalData;
    }

    return [...originalData].sort((a, b) => {
      const aValue = a[sortConfig.key as string];
      const bValue = b[sortConfig.key as string];

      // Handle specific sorting for "standard" if needed
      if (sortConfig.key === "standard") {
        const aStandard = parseInt(aValue.replace(/\D/g, ""), 10);
        const bStandard = parseInt(bValue.replace(/\D/g, ""), 10);
        return sortConfig.direction === "ascending"
          ? aStandard - bStandard
          : bStandard - aStandard;
      }

      // Default sorting logic
      if (aValue < bValue) return sortConfig.direction === "ascending" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "ascending" ? 1 : -1;
      return 0;
    });
  }, [data, sortConfig.key, sortConfig.direction]);

  return {
    sortedData,
    handleSort,
    getSortIcon,
  };
};

export default useSorting;
