import { useState, useEffect } from "react";
import { allCampusStandardAndDivision } from "../../services/api";

export const useStudentMonthlyFilters = () => {
  const [studentMonthlyFilters, setStudentMonthlyFilters] = useState({
    month: "",
    standard: "",
    section: "",
  });

  const [standardAndSectionMonthlyData, setStandardAndSectionMonthlyData] =
    useState<any>(null);
  const [selectedMonthlyStandard, setSelectedMonthlyStandard] = useState("");

  const [filtersCleared, setFiltersCleared] = useState(false);

  useEffect(() => {
    const fetchFilterData = async () => {
      const data = await allCampusStandardAndDivision();
      if (data) {
        setStandardAndSectionMonthlyData(data.standardAndDivisionMap);
      }
    };

    fetchFilterData();
  }, []);

  const handleStudentMonthlyFilterChange = (key: string, value: string) => {
    setStudentMonthlyFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleStudentMonthlyStandardChange = (value: string) => {
    if (!value) {
      setStudentMonthlyFilters((prev) => ({
        ...prev,
        standard: value,
        section: "",
      }));
    } else {
      const selectedStandardKey = Object.keys(
        standardAndSectionMonthlyData
      ).find((key) => key.split(":")[1] === value);

      if (selectedStandardKey) {
        setSelectedMonthlyStandard(selectedStandardKey);
        const sections = standardAndSectionMonthlyData[selectedStandardKey];

        setStudentMonthlyFilters((prev) => ({
          ...prev,
          standard: value,
          section: sections && sections.length > 0 ? sections[0] : "",
        }));
      }
    }
  };

  const handleStudentMonthlyFilterSubmit = () => {
    if (
      !studentMonthlyFilters.month &&
      !studentMonthlyFilters.standard &&
      !studentMonthlyFilters.section
    ) {
      alert("Please enter a search term or select a filter.");
      return;
    }
  };

  const handleStudentMonthlyClearSubmit = () => {
    setStudentMonthlyFilters({
      month: "",
      standard: "",
      section: "",
    });

    setFiltersCleared(true);
  };

  return {
    studentMonthlyFilters,
    standardAndSectionMonthlyData,
    selectedMonthlyStandard,
    handleStudentMonthlyFilterChange,
    handleStudentMonthlyStandardChange,
    handleStudentMonthlyFilterSubmit,
    handleStudentMonthlyClearSubmit,
  };
};
