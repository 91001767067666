import React, { useState } from "react";

interface Tab {
  name: string;
  content: React.ReactNode; // You can customize this to be more complex if necessary
}

interface TabsProps {
  tabsData: Tab[];
  activeTabIndex?: number; // Receive active tab index from parent
  onTabChange?: (index: number) => void; // Callback to notify parent of tab change
  customContentClass?: string;
  activeTabColor?: string; // Custom color for the active tab
  inactiveTabColor?: string; // Custom color for inactive tabs
  activeTabTextColor?: string; // Custom text color for the active tab
  hoverTabColor?: string; // Custom color for the hover state
  hoverTextColor?: string; // Custom text color for hover state
  inactiveTabTextColor?: string; // Custom text color for the active tab
  customButtonClass?: string;
  buttonBorder?: string;
}

const Tabs: React.FC<TabsProps> = ({
  tabsData,
  activeTabIndex: controlledActiveTabIndex, // Controlled from parent
  onTabChange,
  customContentClass = "",
  activeTabColor = "#e8c2ca",
  inactiveTabColor = "#e8c2ca",
  activeTabTextColor = "#b392ac",
  hoverTabColor = "#b392ac",
  hoverTextColor = "#fff",
  inactiveTabTextColor = "#000",
  customButtonClass,
  buttonBorder = "",
}) => {
  const [internalActiveTabIndex, setInternalActiveTabIndex] = useState(0);
  const activeTabIndex =
    controlledActiveTabIndex !== undefined
      ? controlledActiveTabIndex
      : internalActiveTabIndex;
  const [hoveredTabIndex, setHoveredTabIndex] = useState<number | null>(null);

  const handleTabClick = (index: number) => {
    if (onTabChange) {
      onTabChange(index); // Notify parent
    } else {
      setInternalActiveTabIndex(index); // Use internal state
    }
  };

  const handleMouseEnter = (index: number) => {
    setHoveredTabIndex(index); // Set the hovered tab index
  };

  const handleMouseLeave = () => {
    setHoveredTabIndex(null); // Reset when mouse leaves the tab
  };

  return (
    <div
      className={`w-full`} // Add right border when last tab is active
    >
      {/* Tab Names */}
      <div className="flex w-full flex-row ">
        {tabsData.map((tab, index) => {
          // Determine the tab's current style based on hover and active state
          const isActive = activeTabIndex === index;
          const isHovered = hoveredTabIndex === index;

          const tabStyle: React.CSSProperties = {
            borderColor: isActive ? activeTabColor : "",
            borderTopColor: isActive ? activeTabColor : "transparent",
            backgroundColor: isActive
              ? "#fff"
              : isHovered
              ? hoverTabColor
              : inactiveTabColor,
            color: isActive
              ? activeTabTextColor
              : isHovered
              ? hoverTextColor
              : inactiveTabTextColor,
            cursor: "pointer", // Make sure the cursor is a pointer
            transition: "all 0.3s", // Smooth transition
          };
          return (
            <button
              key={index}
              onClick={() => handleTabClick(index)}
              onMouseEnter={() => handleMouseEnter(index)} // When mouse enters, set hover state
              onMouseLeave={handleMouseLeave} // When mouse leaves, reset hover state
              style={tabStyle}
              className={`font-semibold transition-all duration-300 flex-1 py-2 border-t-4 ${
                index !== 0 && `${buttonBorder}`
              }
              ${index === 0 && "rounded-ss-md"} ${
                index === tabsData.length - 1 && "rounded-se-md"
              } ${customButtonClass}`} // Separation lines
            >
              {tab.name}
            </button>
          );
        })}
      </div>
      <div className={`flex-1 mt-0`}>
        <div
          className={` ${"shadow-md py-6 px-3 rounded-es-md rounded-ee-md"} ${customContentClass}`}
        >
          {tabsData[activeTabIndex].content}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
