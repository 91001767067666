import { useState } from "react";
import { getAdmittedStudent } from "../../services/api";
import { useNavigate } from "react-router-dom";

const useStudentAdmissionHandlePopup = (
  getTrimmedFormData: any,
  setLoading: any,
  setFormData: any,
  setErrors: any,
  setActiveTab: any,
  setSelectedStandard: any,
  setSelectedDivision: any
) => {
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const [toastConfig, setToastConfig] = useState<{
    show: boolean;
    message: string;
    type: "error" | "success" | "info";
    duration: number;
  }>({
    show: false,
    message: "",
    type: "error",
    duration: 3000,
  });

  const navigate = useNavigate();

  const handleConfirm = () => {
    setShowConfirmPopup(true);
  };

  const handleSubmit = async () => {
    const trimmedFormData = getTrimmedFormData();
    // Prepare the data to be sent to the API
    const studentInfo = trimmedFormData.studentInfo;
    const parentInfo = trimmedFormData.parentInfo;
    const admissionDetails = trimmedFormData.admissionDetails;

    // Format DOB to YYYY-MM-DD format
    const dob = studentInfo.dob
      ? new Date(studentInfo.dob).toISOString().split("T")[0]
      : "";

    const apiParams = {
      standard: admissionDetails.standard.split(":")[0] || "",
      division: admissionDetails.division || "",
      firstName: studentInfo.firstName || "",
      middleName: studentInfo.middleName || "",
      lastName: studentInfo.lastName || "",
      dob: dob,
      gender: studentInfo.gender || "",
      email: parentInfo.email || "",
      phoneNo: parentInfo.contactNumber || "",
      address: studentInfo.residentialAddress || "",
      status: "ACT",
    };

    try {
      // Send the data to the API
      const response = await getAdmittedStudent(apiParams);

      if (response) {
        sessionStorage.setItem("studentId", response.id);
        setShowSubmitPopup(true);
      }
    } catch (error) {
      console.error("Error submitting form: ", error);
      setToastConfig({
        show: true,
        message: "There was an error submitting the form. Please try again.",
        type: "error",
        duration: 3000,
      });
    }

    console.log("form data: ", trimmedFormData);
  };

  const handleGoToStudentDetails = () => {
    setShowSubmitPopup(false);
    const studentId = sessionStorage.getItem("studentId");

    navigate(`/student_management/student_list/student_details/${studentId}`);
  };

  const handleNewAdmit = () => {
    setShowSubmitPopup(false);
    setLoading(true);
    // Simulate the process (e.g., reset form fields, change tab)
    setTimeout(() => {
      // Reset form data or perform necessary actions here
      // Example:
      setFormData({
        studentInfo: {},
        parentInfo: {},
        admissionDetails: {},
        additionalInfo: {},
      });
      setErrors({}); // Clear any errors
      setActiveTab(0); // Reset to the first tab
      setLoading(false); // Reset loading state
      setSelectedStandard("");
      setSelectedDivision("");
    }, 500); // You can adjust the delay as needed
  };

  return {
    setToastConfig,
    handleConfirm,
    showConfirmPopup,
    setShowConfirmPopup,
    handleSubmit,
    showSubmitPopup,
    handleGoToStudentDetails,
    handleNewAdmit,
    toastConfig,
  };
};

export default useStudentAdmissionHandlePopup;
