import React, { useEffect, useState } from "react";
import DropdownSearch from "../../components/common/DropdownSearch";
import { FaCheck, FaPlus } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import Popup from "../../components/common/Popup";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCampusDetails,
  getTenantDetails,
  getUpdatedTenantDetails,
} from "../../services/api";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import Toast from "../../components/common/Toast";
import TenantTabsData from "../../components/features/tenantSetup/TenantTabsData";

type Tenant = {
  tenantId: number;
  tenantName: string;
  internalCode?: string | undefined;
  success: boolean;
  errorMessage?: string;
  httpStatus?: string;
};

type Campus = {
  campusId: number;
  campusName: string;
  campusInternalCode: string;
  success?: boolean;
  errorMessage?: string;
  httpStatus?: string;
  isDefault: string;
};

type TenantDetails = {
  tenantId: number;
  tenantName: string;
  internalCode: string | undefined;
};

type CampusDetails = {
  campusName: string;
  campusId: number;
  isDefault: string;
  campusInternalCode: string;
};

const TenantSetup: React.FC = () => {
  const [selectedTenant, setSelectedTenant] = useState<string | null>(null);
  const [tenantPopupOpen, setTenantPopupOpen] = useState(false);
  const [campusPopupOpen, setCampusPopupOpen] = useState(false);
  const [tenantDetailsToEdit, setTenantDetailsToEdit] =
    useState<TenantDetails | null>(null);
  const [campusDetailsToEdit, setCampusDetailsToEdit] = useState<Campus | null>(
    null
  );
  const [tenantDetails, setTenantDetails] = useState<TenantDetails[] | null>(
    null
  );
  const [campusDetails, setCampusDetails] = useState<CampusDetails[] | null>(
    null
  );
  const [tenantsDataLoading, setTenantsDataLoading] = useState(false);
  const [campusDataLoading, setCampusDataLoading] = useState(false);
  const [selectedCampus, setSelectedCampus] = useState<number | null>(null);
  const [toastConfig, setToastConfig] = useState<{
    show: boolean;
    message: string;
    type: "error" | "success" | "info";
    duration: number;
  }>({
    show: false,
    message: "",
    type: "error",
    duration: 3000,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const tenantDetailsFieldLabels: { [key: string]: string } = {
    tenantName: "Tenant Name",
    internalCode: "Internal Code",
  };

  const campusDetailsFieldLabels: { [key: string]: string } = {
    campusName: "Campus Name",
    internalCode: "Internal Code",
  };

  useEffect(() => {
    fetchTenantsData();
  }, []);

  useEffect(() => {
    const savedState = sessionStorage.getItem("tenantState");
    if (
      location.state?.fromCreateTenant ||
      location.state?.fromCreateCampus ||
      savedState
    ) {
      if (savedState) {
        const parsedState = JSON.parse(savedState);

        // Restore tenant selection only if it exists
        if (parsedState.selectedTenant) {
          setSelectedTenant(parsedState.selectedTenant);

          // Wait until tenantNames is available
          const tenantId = tenantDetails?.find(
            (tenant) => tenant.tenantName === parsedState.selectedTenant
          )?.tenantId;

          // Storing tenant ID in sessionStorage
          if (tenantId !== undefined) {
            sessionStorage.setItem("tenantId", tenantId.toString()); // Ensure tenantId is stored as a string
          } else {
            // Handle the case where tenantId is undefined, e.g., store "0" or leave it empty
            sessionStorage.setItem("tenantId", ""); // Or some fallback value
          }

          if (tenantId) {
            fetchCampusData(tenantId);
          }
        }
      }
    }
  }, [
    location.state?.fromCreateTenant,
    location.state?.fromCreateCampus,
    tenantDetails,
  ]);

  const fetchTenantsData = async () => {
    setTenantsDataLoading(true);
    try {
      const response: Tenant[] = await getTenantDetails();

      // Store only tenantId, tenantName, and internalCode
      const tenantDetailsWithMinimalData = response.map((tenant) => ({
        tenantId: tenant.tenantId,
        tenantName: tenant.tenantName,
        internalCode: tenant.internalCode, // Only these 3 fields
      }));

      setTenantDetails(tenantDetailsWithMinimalData);

      if (
        !location.state?.fromCreateTenant &&
        !location.state?.fromCreateCampus &&
        tenantDetailsWithMinimalData.length > 0
      ) {
        // Default to the first tenant if no tenant is selected
        setSelectedTenant(tenantDetailsWithMinimalData[0].tenantName);
        sessionStorage.setItem(
          "tenantState",
          JSON.stringify({
            selectedTenant: tenantDetailsWithMinimalData[0].tenantName,
          })
        );
        fetchCampusData(tenantDetailsWithMinimalData[0].tenantId);
      }
    } catch (error) {
      console.error("Error fetching tenant names", error);
    } finally {
      setTenantsDataLoading(false);
    }
  };

  const fetchCampusData = async (tenantId: number) => {
    setCampusDataLoading(true);
    try {
      const response: Campus[] = await getCampusDetails({
        tenantOrCampusId: tenantId,
      });

      const campusList = response.map((campus) => ({
        campusId: campus.campusId,
        campusName: campus.campusName,
        campusInternalCode: campus.campusInternalCode || "",
        isDefault: campus.isDefault || "",
      }));
      setCampusDetails(campusList);
    } catch (error) {
      console.error("Error fetching tenant and campus details", error);
    } finally {
      setCampusDataLoading(false);
    }
  };

  const handleTenantSelection = (tenantName: string) => {
    const userSelectedTenant = tenantDetails?.find(
      (tenant) => tenant.tenantName === tenantName
    );

    if (userSelectedTenant) {
      setSelectedTenant(tenantName); // Set the selected tenant name
      // Save selected tenant in session storage
      sessionStorage.setItem(
        "tenantState",
        JSON.stringify({ selectedTenant: tenantName })
      );

      fetchCampusData(userSelectedTenant.tenantId);
    } else {
      setSelectedTenant(null);
      sessionStorage.removeItem("tenantState"); // Clear stored state if selection is removed
    }

    setSelectedCampus(null);
  };

  const openTenantEditPopup = () => {
    const tenant = tenantDetails?.find(
      (tenant) => tenant.tenantName === selectedTenant
    );

    if (selectedTenant && tenant) {
      setTenantDetailsToEdit({
        tenantId: tenant.tenantId,
        tenantName: tenant.tenantName,
        internalCode: tenant.internalCode || "",
      });
      setTenantPopupOpen(true);
    } else {
      setToastConfig({
        show: true,
        message: "No Details to edit",
        type: "error",
        duration: 3000,
      });
    }
  };

  const openCampusEditPopup = (campus: CampusDetails) => {
    if (campus) {
      setCampusDetailsToEdit(campus); // Set the campus details to be edited
      setCampusPopupOpen(true); // Open the popup
    } else {
      setToastConfig({
        show: true,
        message: "No Details to edit",
        type: "error",
        duration: 3000,
      });
    }
  };

  const handleTenantSaveChanges = async (newDetails: TenantDetails) => {
    if (newDetails) {
      const tenantToCompare = tenantDetails?.find(
        (tenant) => tenant.tenantName === selectedTenant
      );

      if (!tenantToCompare) {
        setToastConfig({
          show: true,
          message: "Tenant not found.",
          type: "error",
          duration: 3000,
        });
        return;
      }

      // Compare the current tenant details with the new details
      const hasChanges =
        tenantToCompare?.tenantName !== newDetails.tenantName ||
        tenantToCompare?.internalCode !== newDetails.internalCode;

      if (!hasChanges) {
        setToastConfig({
          show: true,
          message:
            "No changes were made. Please update the details before saving.",
          type: "error",
          duration: 3000,
        });
        return; // No changes, don't proceed with the API call
      }

      try {
        // Make API call to save the updated details
        const tenantId = tenantDetails?.find(
          (tenant) => tenant.tenantName === selectedTenant
        )?.tenantId;

        if (tenantId) {
          await getUpdatedTenantDetails({
            tenantId,
            tenantName: newDetails.tenantName,
            tenantIntCode: newDetails.internalCode,
          });

          // Close the popup after successful update
          setTenantPopupOpen(false);

          // Refresh the tenant data to show updated details
          fetchTenantsData();

          // Automatically select the updated tenant and show details
          setSelectedTenant(selectedTenant);
          fetchCampusData(tenantId);
        }
      } catch (error) {
        console.error("Error updating tenant details", error);
      }
    }
  };

  const handleCampusSaveChanges = async (updatedCampus: CampusDetails) => {
    if (updatedCampus) {
      const hasChanges =
        campusDetailsToEdit?.campusName !== updatedCampus.campusName ||
        campusDetailsToEdit?.campusInternalCode !==
          updatedCampus.campusInternalCode;

      if (!hasChanges) {
        // No changes made, show a toast message alert
        setToastConfig({
          show: true,
          message:
            "No changes were made. Please update the details before saving.",
          type: "error",
          duration: 3000,
        });
        return; // Don't proceed further if no changes were made
      }

      try {
        const tenantId = tenantDetails?.find(
          (tenant) => tenant.tenantName === selectedTenant
        )?.tenantId;

        if (tenantId && campusDetailsToEdit) {
          // Proceed with saving the updated campus details
          await getUpdatedTenantDetails({
            tenantId,
            tenantName: selectedTenant!,
            tenantIntCode: updatedCampus.campusInternalCode,
          });

          // Close the popup after successful update
          setCampusPopupOpen(false);

          // Refresh the campus data
          fetchCampusData(tenantId);
        }
      } catch (error) {
        console.error("Error updating campus details", error);
      }
    }
  };

  const handleCampusClick = (campus: number) => {
    setSelectedCampus(campus); // Set selected campus
  };

  return (
    <>
      {tenantsDataLoading ? (
        <LoadingSpinner message="Loading..." />
      ) : (
        <div className="bg-white rounded shadow-md px-7 py-3">
          <div className="flex justify-between items-center">
            <div className="flex gap-x-3 items-center">
              <span className="text-lg font-bold">Tenant:</span>
              <DropdownSearch
                suggestions={tenantDetails?.map((tenant) => tenant.tenantName)} // Extract only tenant names
                onSelect={handleTenantSelection} // Pass selection handler to DropdownSearch
                value={selectedTenant || ""}
              />
            </div>

            <div>
              <button
                onClick={() => {
                  if (selectedTenant) {
                    sessionStorage.setItem(
                      "tenantState",
                      JSON.stringify({ selectedTenant })
                    );
                  } else {
                    sessionStorage.removeItem("tenantState"); // Ensure no state is stored if nothing is selected
                  }

                  navigate("/master_setup/tenant_setup/create_tenant");
                }}
                className="py-1 px-4 bg-orange-400 hover:bg-orange-500 text-white rounded-3xl flex items-center gap-x-2"
              >
                <FaPlus /> Create New Tenant
              </button>
            </div>
          </div>
        </div>
      )}
      {campusDataLoading ? (
        <LoadingSpinner />
      ) : (
        selectedTenant &&
        (tenantDetails || campusDetails) && (
          <>
            <div className="mt-3 bg-white rounded shadow-md px-7 py-3">
              <div className="ml-[4.3rem] w-10/12">
                <div className="flex bg-slate-50 border-2 shadow-sm border-gray-300 rounded p-2 gap-x-3">
                  <div className="flex-1 flex flex-wrap gap-x-5 gap-y-3">
                    {tenantDetails &&
                      tenantDetails.map((tenant) => {
                        if (tenant.tenantName === selectedTenant) {
                          return (
                            <>
                              {Object.keys(tenant).map((key) => {
                                if (key !== "tenantId") {
                                  return (
                                    <div
                                      key={key}
                                      className="text-sm text-gray-800 bg-gray-100 px-3 py-1 rounded-lg border border-gray-300"
                                    >
                                      <span className="font-bold">
                                        {tenantDetailsFieldLabels[key] || key}:{" "}
                                      </span>
                                      {tenant[key as keyof TenantDetails]}
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </>
                          );
                        }
                        return null;
                      })}
                  </div>
                  <div>
                    <button
                      onClick={openTenantEditPopup}
                      className="flex items-center gap-x-2 px-4 py-1 rounded-3xl bg-red-400 text-white hover:bg-red-500"
                    >
                      Edit <MdModeEdit size={20} />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3 bg-white rounded shadow-md px-7 py-3">
              {/* Campus cards container */}
              <div className="flex justify-end mb-3">
                <button
                  onClick={() => {
                    if (selectedTenant) {
                      sessionStorage.setItem(
                        "tenantState",
                        JSON.stringify({ selectedTenant })
                      );
                    } else {
                      sessionStorage.removeItem("tenantState"); // Ensure no state is stored if nothing is selected
                    }
                    navigate("/master_setup/tenant_setup/create_campus");
                  }}
                  className="py-1 px-4 bg-orange-400 hover:bg-orange-500 text-white rounded-3xl flex items-center gap-x-2"
                >
                  <FaPlus /> Create New Campus
                </button>
              </div>

              <div
                className="overflow-auto px-2 pt-2"
                style={{ maxHeight: "160px" }}
              >
                {campusDetails?.map((campus, index) => (
                  <div
                    key={index}
                    className={`p-4 mb-4 bg-slate-50 border-2 shadow-sm border-gray-300 rounded flex-1 flex justify-between relative ${
                      selectedCampus === campus.campusId
                        ? "border-green-500"
                        : ""
                    }`}
                  >
                    {selectedCampus === campus.campusId && (
                      <div className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 bg-green-500 rounded-full p-1 text-white">
                        <FaCheck size={10} />
                      </div>
                    )}

                    {/* Campus Details */}
                    <div
                      className="flex items-center gap-4 cursor-pointer"
                      onClick={() => handleCampusClick(campus.campusId)}
                    >
                      {/* Campus Name */}
                      <div className="text-sm text-gray-800 bg-gray-100 px-3 py-1 rounded-lg border border-gray-300">
                        <span className="font-bold">Campus Name:</span>{" "}
                        {campus.campusName}
                      </div>

                      {/* Internal Code */}
                      <div className="text-sm text-gray-800 bg-gray-100 px-3 py-1 rounded-lg border border-gray-300">
                        <span className="font-bold">Internal Code:</span>{" "}
                        {campus.campusInternalCode}
                      </div>
                    </div>

                    {/* Edit Button */}
                    <button
                      onClick={() => openCampusEditPopup(campus)}
                      className="flex items-center gap-x-2 px-4 py-1 rounded-3xl bg-red-400 text-white hover:bg-red-500"
                    >
                      Edit <MdModeEdit size={20} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </>
        )
      )}

      {selectedCampus && tenantDetails && (
        <TenantTabsData
          tenantId={
            tenantDetails?.find(
              (tenant) => tenant.tenantName === selectedTenant
            )?.tenantId
          }
        />
      )}

      <Popup
        isPopupOpen={tenantPopupOpen}
        title="Edit Tenant Details"
        divClassName="bg-[#f8edeb]"
        content={
          <div className="px-2">
            <form className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                {tenantDetailsToEdit && (
                  <>
                    <div className="flex flex-row gap-4 items-center">
                      <label className="font-semibold w-1/3 text-right">
                        Tenant Name:
                      </label>
                      <input
                        type="text"
                        defaultValue={tenantDetailsToEdit.tenantName}
                        className="border px-2 py-1 rounded-lg w-8/12"
                        onChange={(e) => {
                          setTenantDetailsToEdit((prev) => ({
                            ...prev!,
                            tenantName: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="flex flex-row gap-4 items-center">
                      <label className="font-semibold w-1/3 text-right">
                        Internal Code:
                      </label>
                      <input
                        type="text"
                        defaultValue={tenantDetailsToEdit.internalCode}
                        className="border px-2 py-1 rounded-lg w-8/12"
                        onChange={(e) => {
                          setTenantDetailsToEdit((prev) => ({
                            ...prev!,
                            internalCode: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        }
        buttons={[
          {
            label: "Save",
            onClick: () => {
              if (tenantDetailsToEdit) {
                handleTenantSaveChanges(tenantDetailsToEdit);
              }
            },
            style: "bg-blue-500 text-white hover:bg-blue-600",
          },
          {
            label: "Cancel",
            onClick: () => setTenantPopupOpen(false),
            style: "bg-gray-300 text-gray-700 hover:bg-gray-400",
          },
        ]}
        onClose={() => setTenantPopupOpen(false)}
        buttonTopBorder={false}
        buttonsGap="justify-end"
      />

      <Popup
        isPopupOpen={campusPopupOpen}
        title="Edit Campus Details"
        divClassName="bg-[#f8edeb]"
        content={
          <div className="px-2">
            <form className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                {campusDetailsToEdit && (
                  <>
                    <div className="flex flex-row gap-4 items-center">
                      <label className="font-semibold w-1/3 text-right">
                        Campus Name:
                      </label>
                      <input
                        type="text"
                        defaultValue={campusDetailsToEdit.campusName}
                        className="border px-2 py-1 rounded-lg w-8/12"
                        onChange={(e) => {
                          setCampusDetailsToEdit((prev) => ({
                            ...prev!,
                            campusName: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="flex flex-row gap-4 items-center">
                      <label className="font-semibold w-1/3 text-right">
                        Internal Code:
                      </label>
                      <input
                        type="text"
                        defaultValue={campusDetailsToEdit.campusInternalCode}
                        className="border px-2 py-1 rounded-lg w-8/12"
                        onChange={(e) => {
                          setCampusDetailsToEdit((prev) => ({
                            ...prev!,
                            internalCode: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        }
        buttons={[
          {
            label: "Save",
            onClick: () => {
              if (campusDetailsToEdit) {
                handleCampusSaveChanges(campusDetailsToEdit); // Save changes
              }
            },
            style: "bg-blue-500 text-white hover:bg-blue-600",
          },
          {
            label: "Cancel",
            onClick: () => setCampusPopupOpen(false),
            style: "bg-gray-300 text-gray-700 hover:bg-gray-400",
          },
        ]}
        onClose={() => setCampusPopupOpen(false)}
        buttonTopBorder={false}
        buttonsGap="justify-end"
      />

      <Toast
        message={toastConfig.message}
        type={toastConfig.type}
        show={toastConfig.show}
        onClose={() => setToastConfig((prev) => ({ ...prev, show: false }))}
        duration={toastConfig.duration}
        position="top" // You can change this to 'top' if you prefer
      />
    </>
  );
};

export default TenantSetup;
