import React from "react";
import SearchBar from "./SearchBar";

interface FilterOption {
  label: string; // Label for the filter
  type: "select" | "text" | "date"; // Type of input field
  options?: string[]; // Dropdown options, required for "select"
  value: string; // Current value of the filter
  onChange: (value: string) => void; // Callback to update the value
  mandatory?: boolean;
}

interface FilterPanelProps {
  heading?: string;
  filters: FilterOption[]; // Array of dynamic filters
  onFilterSubmit: () => void; // Callback when "Filter" button is clicked
  buttonText?: string;
  containerStyle?: string;
  searchTerm?: string;
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // Callback to update search term
  searchBarClassName?: string; // Optional className for custom styling of the SearchBar
  showSearch?: boolean; // Optional prop to control the display of the search bar
  onClearSubmit?: () => void; // Add this to handle the Clear action
  placeholder?: string;
}

const FilterPanel: React.FC<FilterPanelProps> = ({
  heading = "",
  filters,
  onFilterSubmit,
  buttonText = "Filter",
  containerStyle = "",
  searchTerm = "",
  onSearchChange, // Undefined if showSearch is false
  searchBarClassName = "",
  showSearch = true,
  onClearSubmit,
  placeholder,
}) => {
  return (
    <div className={`bg-white shadow-lg p-4 rounded-md ${containerStyle}`}>
      {/* Responsive Panel Header */}
      <h2 className="text-lg font-semibold text-gray-700 mb-1">{heading}</h2>

      {/* Responsive Filters Grid */}
      <div className="grid grid-cols-6 gap-4 mb-4">
        {/* Conditionally Render Search Bar */}
        {showSearch && (
          <div className="flex justify-center items-end">
            <SearchBar
              placeholder={placeholder}
              value={searchTerm}
              onChange={onSearchChange}
              customStyling={searchBarClassName}
            />
          </div>
        )}

        {filters.map((filter, index) => (
          <div key={index}>
            {/* Label */}
            <label className="block text-gray-600 text-sm mb-1">
              {filter.label}
              {filter.mandatory && (
                <span className="text-red-500 font-semibold"> *</span>
              )}
            </label>

            {/* Input Field */}
            {filter.type === "select" ? (
              <select
                value={filter.value}
                onChange={(e) => filter.onChange(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-300"
              >
                <option value=""></option>
                {filter.options?.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type={filter.type}
                value={filter.value}
                onChange={(e) => filter.onChange(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-300"
              />
            )}
          </div>
        ))}
      </div>

      <div className="flex gap-x-5">
        {/* Filter or Search Button */}
        <div className="text-right">
          <button
            onClick={onFilterSubmit}
            className="bg-[#0b6e4feb] hover:bg-[#046144] text-white font-semibold py-2 px-4 rounded-md transition"
          >
            {buttonText}
          </button>
        </div>

        {/* Filter or Search Button */}
        <div className="text-right">
          <button
            onClick={onClearSubmit}
            className="bg-[#5f003ad4] hover:bg-[#5f003a] text-white font-semibold py-2 px-4 rounded-md transition"
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;
