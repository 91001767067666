import axios from "axios";
import { getAuthToken } from "../utils/loginSecureUtility";

const baseURL = process.env.REACT_APP_API_URL;

// Helper function to get the Authorization headers
const getAuthHeaders = () => {
  const authToken = getAuthToken();
  const campusId = getCampusId();

  if (!authToken) {
    throw new Error("User not authenticated. Please log in.");
  }

  return {
    Authorization: `Bearer ${authToken}`,
    "X-CAMPUS-ID": campusId,
  };
};

// Helper function for API requests (GET)
const apiGetRequest = async (url: string, headers: object) => {
  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error: any) {
    handleAuthError(error);
    throw new Error("An error occurred while making the GET request.");
  }
};

// Helper function for API requests (POST)
const apiPostRequest = async (url: string, data: object, headers: object) => {
  try {
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (error: any) {
    handleAuthError(error);
    throw new Error("An error occurred while making the POST request.");
  }
};

// Helper function to handle authentication errors (Token Expiry, etc.)
const handleAuthError = (error: any) => {
  if (error.response && error.response.data) {
    const errorData = error.response.data;
    if (
      errorData.error === "TokenSignatureError" ||
      errorData.error === "TokenExpiredError" ||
      errorData.error === "TokenUnknownError"
    ) {
      sessionStorage.clear();
      window.location.href = "/session_expired"; // Redirect to session expired page
    } else {
      throw errorData;
    }
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// Helper to get the campus ID from sessionStorage
const getCampusId = (): number => {
  const campusId = Number(sessionStorage.getItem("selectedCampusId"));
  // console.log("getCampusId: Retrieved Campus ID:", campusId); // Debugging log

  if (isNaN(campusId) || campusId <= 0) {
    throw new Error("Invalid campus ID. Please select a valid campus.");
  }

  return campusId;
};

// Function to log in and get the token
export const loginUser = async (userName: string, password: string) => {
  try {
    const API_URL = baseURL + "/api/users/loginsecuser";

    // Send the userName and password as JSON in the body of the POST request
    const response = await axios.post(API_URL, {
      userName,
      password,
    });

    // Destructure the response data
    const {
      userName: returnedUserName,
      authToken,
      tenantId,
      tenantName,
      campuses,
      menuTabs,
    } = response.data;

    // Save all the relevant data in sessionStorage
    sessionStorage.setItem("userName", returnedUserName);
    sessionStorage.setItem("authToken", authToken);
    sessionStorage.setItem("tenantId", tenantId);
    sessionStorage.setItem("tenantName", tenantName);
    sessionStorage.setItem("campuses", JSON.stringify(campuses));
    sessionStorage.setItem("menuTabs", JSON.stringify(menuTabs));

    // Set default campus ID if it's not set
    const defaultCampusId = campuses.find(
      (campus: { isDefault: string }) => campus.isDefault === "Y"
    )?.campusId;
    if (defaultCampusId) {
      sessionStorage.setItem("selectedCampusId", defaultCampusId.toString());
    }

    return response.data; // Return the complete response data
  } catch (error: any) {
    if (error.response && error.response.data) {
      console.error("Error response:", error.response.data); // Log the error response
      throw error.response.data; // Pass the error data to the caller
    }
    console.error("Unexpected error:", error); // Log unexpected errors
    throw new Error("An unexpected error occurred.");
  }
};

// Fetch Students Info - Only call this after the user is logged in
export const studentsInfo = async () => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + "/api/students/getAllStudents";
  return apiGetRequest(apiUrl, headers);
};

// Fetch Home Search Results - Requires authToken
export const homeSearchResults = async (params: {
  search: string;
  searchType: string;
}) => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + "/api/home/flexiSearch";
  return apiPostRequest(apiUrl, params, headers);
};

// Fetch Student details
export const studentDetails = async (studentId: number) => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + `/api/students/getStudent/${studentId}`;
  return apiGetRequest(apiUrl, headers);
};

// Fetch Student standard and division
export const standardAndDivision = async () => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + `/api/home/getStandardAndDivision`;
  return apiGetRequest(apiUrl, headers);
};

// Fetch filters
export const allCampusStandardAndDivision = async () => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + `/api/home/getCampusStandardAndDivision`;
  return apiGetRequest(apiUrl, headers);
};

// searched student
export const searchedStudent = async (params: {
  standard: string;
  studentNameOrId: string;
  division: string;
}) => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + "/api/students/searchStudentByStandard";
  return apiPostRequest(apiUrl, params, headers);
};

// admission form dropdowns
export const getLookupValues = async (params: String[]) => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + "/api/system/getLookupValues";
  return apiPostRequest(apiUrl, params, headers);
};

// tenant details
export const getTenantDetails = async () => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + `/api/home/getTenants`;
  return apiGetRequest(apiUrl, headers);
};

// campus details
export const getCampusDetails = async (params: {
  tenantOrCampusId: number;
}) => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + "/api/home/getTenantAndCampus";
  return apiPostRequest(apiUrl, params, headers);
};

// update tenant details
export const getUpdatedTenantDetails = async (params: {
  tenantId: number;
  tenantName: string;
  tenantIntCode: string | undefined;
}) => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + "/api/home/saveOrUpdateTenant";
  return apiPostRequest(apiUrl, params, headers);
};

// get user details
export const getUsersByTenant = async (params: { tenantId: number }) => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + "/api/home/getSecUsersByTenant";
  return apiPostRequest(apiUrl, params, headers);
};

// submit the student admission form
export const getAdmittedStudent = async (params: {
  standard: string;
  division: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  gender: string;
  email: string;
  phoneNo: string;
  address: string;
  status: string;
}) => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + "/api/students/saveStudent";
  return apiPostRequest(apiUrl, params, headers);
};

// save user updated details
export const getUpdatedUserDetails = async (params: {
  secUserId: number;
  password: string;
  accountLocked: string;
  accountActive: string;
  invalidLoginAttempt: string;
}) => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + "/api/users/updateSecUser";
  return apiPostRequest(apiUrl, params, headers);
};

// standard and division options in admission form
export const getAdmissionFormStandardAndDivision = async () => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + `/api/home/getStandardAndDivisionForAdmission`;
  return apiGetRequest(apiUrl, headers);
};

// update campus details
export const getUpdatedCampusDetails = async (params: {
  campusId: number;
  campusName: string;
  tenantId: number;
  campusInternalCode: string;
  isDefault: string;
}) => {
  const headers = getAuthHeaders();
  const apiUrl = baseURL + "/api/home/saveOrUpdateCampus";
  return apiPostRequest(apiUrl, params, headers);
};
