import { RowConfig } from "../types/FormDataTypes"; // Ensure you import your types if needed

// Define field configurations for each tab using tab names as keys
const tabConfigs: Record<string, RowConfig[]> = {
  studentInfo: [
    { fields: ["firstName", "middleName", "lastName"] },
    { fields: ["dob", "gender", "blood_group"] },
    { fields: ["nationality", "religion", "category"] },
    { fields: ["previousSchoolName"] },
    { fields: ["residentialAddress", "permanentAddress"] },
    { fields: ["emergencyContactAddress"] },
    { fields: ["photo"] },
  ],
  parentInfo: [
    { fields: ["fatherName", "fatherOccupation"] },
    { fields: ["motherName", "motherOccupation"] },
    { fields: ["contactNumber", "email", "relation"] },
  ],
  admissionDetails: [
    { fields: ["standard", "division"] },
    { fields: ["admissionDate", "admissionMode", "admissionReason"] },
    { fields: ["medicalInfo", "medicalContact"] },
    { fields: ["doctorName", "doctorContact"] },
    { fields: ["academicInfo", "specialInterest"] },
    { fields: ["parentEduLevel"] },
  ],
  additionalInfo: [
    { fields: ["hearAboutUs"] },
    { fields: ["commConsent"] },
    { fields: ["transportMode"] },
    { fields: ["bankAccDetails"] },
    { fields: ["comments"] },
    { fields: ["medicalConsent"] },
    { fields: ["photoConsent"] },
  ],
};

// Export a function to get row configurations for a specific tab by name
export const getRowConfig = (tabName: string): RowConfig[] => {
  return tabConfigs[tabName] || [];
};
