import React, { ReactNode, useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { TiArrowSortedDown } from "react-icons/ti";

interface TabData {
  name: string;
  label: ReactNode;
  options: string[];
  content: ReactNode[];
}

interface TabDropdownProps {
  tabs: TabData[];
}

const TabDropdown: React.FC<TabDropdownProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: number]: string | null;
  }>(() => ({
    0: tabs[0]?.options[0] || null, // Default to first tab's first option if available
  }));
  const [dropdownVisible, setDropdownVisible] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        setDropdownVisible(null); // Close dropdown if clicked outside
      }
    };

    // Add the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectOption = (tabIndex: number, optionIndex: number) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [tabIndex]: tabs[tabIndex].options[optionIndex],
    }));
    setDropdownVisible(null); // Hide dropdown after selection
  };

  return (
    <div className="w-full">
      {/* Tabs */}
      <div className="flex w-full bg-[#B79C66] rounded-md">
        {tabs.map((tab, tabIndex) => (
          <div key={tabIndex} className="relative">
            <button
              onClick={() =>
                setDropdownVisible((prev) =>
                  prev === tabIndex ? null : tabIndex
                )
              }
              className={`flex items-center gap-x-2 px-4 py-2 font-medium  transition ${
                activeTab === tabIndex
                  ? "bg-white text-[#BCA371] border-b-[3px] border-b-[#00916fad]"
                  : "bg-[#BCA371] text-white hover:bg-[#927c4f]"
              } ${
                tabIndex !== 0 &&
                activeTab !== tabIndex &&
                "border-l border-l-white"
              } ${tabIndex === 0 && "rounded-s-md"}`}
            >
              {tab.label} <TiArrowSortedDown />
            </button>

            {/* Dropdown */}
            <AnimatePresence>
              {dropdownVisible === tabIndex && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="absolute left-0 mt-1 w-40 bg-white shadow-lg rounded-lg overflow-hidden z-10"
                  ref={dropdownRef}
                >
                  {tab.options.map((option, optionIndex) => (
                    <button
                      key={option}
                      onClick={() => {
                        setActiveTab(tabIndex);
                        handleSelectOption(tabIndex, optionIndex);
                      }}
                      className="block w-full px-4 py-2 text-left hover:bg-blue-100"
                    >
                      {option}
                    </button>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>

      {/* Content Display */}
      <div className="mt-4">
        {selectedOptions[activeTab] ? (
          <div key={`${activeTab}-${selectedOptions[activeTab]}`}>
            {
              tabs[activeTab].content[
                tabs[activeTab].options.indexOf(selectedOptions[activeTab]!)
              ]
            }
          </div>
        ) : (
          <p className="text-gray-500">Select an option from the dropdown.</p>
        )}
      </div>
    </div>
  );
};

export default TabDropdown;
