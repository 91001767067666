import { FieldConfig } from "../types/FormDataTypes"; // Ensure you import your types if needed

// Define field configurations for each tab using tab names as keys
const tabConfigs: Record<string, FieldConfig[]> = {
  studentInfo: [
    {
      name: "firstName",
      label: "Name",
      type: "text",
      placeholder: "Enter first name",
      required: true,
      validation: ["required", "isAlpha"], // Add validation rules
    },
    {
      name: "middleName",
      type: "text",
      placeholder: "Enter middle name",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "lastName",
      type: "text",
      placeholder: "Enter last name",
      required: true,
      validation: ["required", "isAlpha"],
    },
    {
      name: "dob",
      label: "Date of Birth",
      type: "date",
      required: true,
      validation: ["required", "isDate"],
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      required: true,
      validation: ["required"],
    },
    {
      name: "blood_group",
      label: "Blood Group",
      type: "select",
      required: false,
    },
    {
      name: "photo",
      label: "Upload Photo",
      type: "file",
    },
    {
      name: "nationality",
      label: "Nationality",
      type: "text",
      placeholder: "Enter nationality",
      required: true,
      validation: ["required", "isAlpha"],
    },
    {
      name: "religion",
      label: "Religion",
      type: "text",
      placeholder: "Enter religion",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "category",
      label: "Category",
      type: "select",
      required: false,
    },
    {
      name: "previousSchoolName",
      label: "Previous School Name",
      type: "text",
      placeholder: "Enter previous school name",
      required: false,
    },
    {
      name: "residentialAddress",
      label: "Residential Address",
      type: "text",
      placeholder: "Enter address",
      required: true,
      validation: ["required"],
    },
    {
      name: "permanentAddress",
      label: "Permanent Address",
      type: "text",
      placeholder: "Enter address",
      required: false,
    },
    {
      name: "emergencyContactAddress",
      label: "Emergency Contact Address",
      type: "text",
      placeholder: "Enter address",
      required: false,
    },
  ],

  parentInfo: [
    {
      name: "fatherName",
      label: "Father's/Guardian Name",
      type: "text",
      placeholder: "Enter father's name",
      required: true,
      validation: ["required", "isAlpha"],
    },
    {
      name: "fatherOccupation",
      label: "Father's/Guardian Occupation",
      type: "text",
      placeholder: "Enter father's Occupation",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "motherName",
      label: "Mother's/Guardian Name",
      type: "text",
      placeholder: "Enter middle name",
      required: true,
      validation: ["required", "isAlpha"],
    },
    {
      name: "motherOccupation",
      label: "Mother's/Guardian Occupation",
      type: "text",
      placeholder: "Enter mother's Occupation",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "contactNumber",
      label: "Parent/Guardian Contact Number",
      type: "tel",
      placeholder: "Enter contact number",
      required: true,
      validation: ["required", "isPhoneNumber", "phoneLength"],
    },
    {
      name: "email",
      label: "Parent/Guardian Email Address",
      type: "email",
      placeholder: "Enter Email Address",
      required: true,
      validation: ["required", "isEmail"],
    },
    {
      name: "relation",
      label: "Relationship to the Student",
      type: "select",
      options: [
        "Father",
        "Mother",
        "Guardian",
        "Legal Guardian",
        "Grandfather",
        "Grandmother",
      ],
      required: true,
      validation: ["required"],
    },
  ],

  admissionDetails: [
    {
      name: "standard",
      label: "Standard",
      type: "select",
      required: true,
      validation: ["required"],
    },
    {
      name: "division",
      label: "Division",
      type: "select",
      required: true,
      validation: ["required"],
    },
    {
      name: "admissionDate",
      label: "Admission Date",
      type: "date",
      required: true,
      validation: ["required"],
    },
    {
      name: "admissionMode",
      label: "Mode of Admission",
      type: "select",
      options: [
        "Direct Admission",
        "Entrance Exam",
        "Merit-Based Admission",
        "Management Quota",
        "Sports/Co-curricular Quota",
      ],
      required: true,
      validation: ["required"],
    },
    {
      name: "admissionReason",
      label: "Reason for Admission",
      type: "text",
      placeholder: "Enter reason",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "medicalInfo",
      label: "Medical History",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "medicalContact",
      label: "Emergency Medical Contact",
      type: "tel",
      placeholder: "Enter contact",
      required: true,
      validation: ["required", "isPhoneNumber", "phoneLength"],
    },
    {
      name: "doctorName",
      label: "Doctor's Name",
      type: "text",
      placeholder: "Enter name",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "doctorContact",
      label: "Doctor's Contact",
      type: "tel",
      placeholder: "Enter contact",
      required: false,
      validation: ["isPhoneNumber", "phoneLength"],
    },
    {
      name: "academicInfo",
      label: "Previous Academic Performance",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "specialInterest",
      label: "Special Talents or Interests",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "parentEduLevel",
      label: "Parent's Education Level",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
  ],

  additionalInfo: [
    {
      name: "hearAboutUs",
      label: "How Did You Hear About Us?",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "commConsent",
      label: "Consent for Communication",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "transportMode",
      label: "Mode of Transport",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "bankAccDetails",
      label: "Bank Account Details for Fees Payment",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "comments",
      label: "Additional Notes or Comments",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "medicalConsent",
      label: "Parental Consent for Medical Treatment",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "photoConsent",
      label: "Parental Consent for Photography",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
  ],
};

// Export a function to get field configurations for a specific tab by name
export const getFieldConfig = (tabName: string): FieldConfig[] => {
  return tabConfigs[tabName] || [];
};
