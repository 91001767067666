import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../services/api";
import "../assets/styles/login.css";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useStudents } from "../contexts/StudentsContext";
import intekspotLogo from "../assets/images/intekspot_logo.png";
import LoadingSpinner from "../components/common/LoadingSpinner";

const LoginPage: React.FC = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Access the context
  const { setAuthToken } = useStudents(); // Get setAuthToken from context

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(""); // Reset any existing error
    setIsLoading(true); // Start loading

    try {
      const trimmedUserName = userName.trim();
      const trimmedPassword = password.trim();

      if (!trimmedUserName || !trimmedPassword) {
        setError("Username and Password are required.");
        setIsLoading(false); // Stop loading
        return;
      }

      // Call the login API
      const response = await loginUser(trimmedUserName, trimmedPassword);

      // Check the status code from the response
      if (response.httpStatus === 200) {
        // Set authToken in context
        setAuthToken(response.authToken);

        // Show "Please wait" spinner while navigating to the dashboard
        setIsLoading(true); // Show loading spinner during the transition

        // After a short delay to show the spinner, navigate to the dashboard
        setTimeout(() => {
          navigate("/dashboard");
        }, 500); // Delay to show the spinner
      } else {
        // If there's an error, show the error message after a brief delay
        setTimeout(() => {
          setError(
            response.errorMessage || "An error occurred. Please try again."
          );
        }, 500); // Brief delay to show loading spinner
      }
    } catch (err: any) {
      // Handle unexpected errors
      setTimeout(() => {
        setError(err.errorMessage || "An unexpected error occurred.");
      }, 500); // Brief delay to show loading spinner
    } finally {
      setIsLoading(false); // Stop loading after request is completed
    }
  };

  return (
    <div className="bg-[url('../images/login-img.jpg')] bg-cover bg-center w-[100%] min-h-screen">
      <div className="login-sec relative flex flex-col items-center justify-center w-[100%] min-h-screen py-2">
        <div
          className={`wrapper w-[400px] relative transition-all duration-500 ${
            isLoading ? "blur-sm" : ""
          }`}
        >
          <form action="#" className="flex flex-col" onSubmit={handleSubmit}>
            <div className="flex flex-col items-center mb-4">
              <img
                src={intekspotLogo}
                alt="intekspot logo"
                className="w-[83%] rounded px-2 py-1"
                style={{
                  background:
                    "linear-gradient(to bottom right, #f8f8f8, #ffd23a)",
                }}
              />
              <h3 className="text-2xl text-white mt-3">EMS</h3>
            </div>

            <div className="input-field">
              <input
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
              <label>Enter your username</label>
            </div>

            <div className="input-field">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span
                className="pswd-hide-icon"
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? (
                  <FiEyeOff className="text-white" />
                ) : (
                  <FiEye className="text-white" />
                )}
              </span>
              <label>Enter your password</label>
            </div>

            {/* Show the loading spinner overlay */}
            {isLoading && (
              <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 text-yellow-300">
                <LoadingSpinner
                  size="h-16 w-16"
                  color="border-white"
                  message="Please wait"
                  fullScreen={false}
                />
              </div>
            )}

            {/* Show the error message if there is an error */}
            {error && !isLoading && (
              <p className="text-center text-[yellow] mt-2">{error}</p>
            )}

            <div className="forget text-right">
              <a href="#">Forgot password?</a>
            </div>

            <button onClick={handleSubmit} className="text-[18px]">
              Log In
            </button>
          </form>
        </div>
        <div className="absolute bottom-0 text-white py-3 bg-[#092236c7] w-full text-center">
          &copy; all rights reserved
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
