import React, { useEffect, useState } from "react";
import { studentDetails } from "../../services/api";
import { useLocation, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import Tabs from "../../components/common/Tabs";
import BackButton from "../../components/common/BackButton";

interface StudentDetails {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  gender: string;
  phoneNumber: string;
  address: string;
  status: string;
  campusResp: string | null;
  standard: string;
  [key: string]: any; // For other dynamic fields like campusResp
}

const studentDetailLabels: Record<string, string> = {
  id: "ID",
  firstName: "First Name",
  lastName: "Last Name",
  fullName: "Full Name",
  email: "Email",
  gender: "Gender",
  phoneNumber: "Phone Number",
  address: "Address",
  status: "Status",
  campusResp: "Campus Response",
  standard: "Standard",
};

// Define the fields you want to display in the page
const fieldsToDisplay: string[] = [
  "id",
  "fullName",
  "standard",
  "phoneNumber",
  "email",
  "gender",
  "address",
];

const StudentDetailsPage: React.FC = () => {
  const { studentId } = useParams<{ studentId: string }>(); // Get studentId from URL params
  const [student, setStudent] = useState<StudentDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const location = useLocation(); // Use location to detect previous page

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        setLoading(true); // Start loading
        if (!studentId) {
          throw new Error("No student ID provided.");
        }
        const data = await studentDetails(Number(studentId)); // Fetch student details
        setStudent(data); // Set the student data
        setError(null); // Clear any previous error
      } catch (err: any) {
        setError(err.message || "Failed to fetch student details."); // Set error
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchStudentDetails();
  }, [studentId]);

  const tabsData = [
    {
      name: "Student Profile",
      content: "Profile, Parent/Guardian Details.",
    },
    {
      name: "Academics & Results",
      content: "Academics, Exams & Results, Assignments.",
    },
    {
      name: "Attendance & Discipline",
      content: "Attendance and Discipline/Remarks.",
    },
    {
      name: "Fees & Payments",
      content: "Tuition fees, payment records, and dues.",
    },
    {
      name: "Health & Extracurricular Activities",
      content: "Medical and Extracurricular Activities.",
    },
    {
      name: "Library",
      content: "Books issued, pending returns, and fines.",
    },
  ];

  if (loading) {
    return <LoadingSpinner message="Loading student details..." />;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>; // Show error message
  }

  // Determine the previous path from where the page was accessed
  const fromPage = location.state?.from || "/student_management/student_list";

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="text-xl font-bold mb-4">Student Details</h1>
        <BackButton to={fromPage} state={{ fromStudentDetails: true }} />
      </div>

      {student ? (
        <div>
          <div className="flex gap-x-3 p-4 bg-white border rounded shadow">
            {/* Image */}
            <div>
              <img
                src="https://www.pngitem.com/pimgs/m/53-533248_cartoon-person-without-face-person-cartoon-hd-png.png"
                alt="student"
                className="w-48 h-52"
              />
            </div>
            <div className="flex-1 bg-slate-200 p-4 border rounded shadow">
              {/* ID and Full Name */}
              <div className="flex items-center gap-x-11 mb-4 rounded-md bg-[#b0c4de] px-2 py-2">
                <p className="text-3xl font-semibold">
                  <strong>{studentDetailLabels["id"]}: </strong>
                  {student.id}
                </p>
                <p className="text-3xl font-semibold">
                  <strong>{studentDetailLabels["fullName"]}: </strong>
                  {student.fullName}
                </p>
              </div>

              {/* Remaining Fields */}
              <div className="grid grid-cols-4 gap-x-5 gap-y-4">
                {fieldsToDisplay.map((field) => {
                  if (
                    field === "id" ||
                    field === "fullName" ||
                    student[field] === null
                  ) {
                    return null;
                  }
                  return (
                    <div key={field}>
                      <strong>{studentDetailLabels[field]}: </strong>
                      <span>{student[field]}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="mt-8">
            <Tabs
              tabsData={tabsData}
              activeTabColor="#e8c2ca"
              inactiveTabColor="#e8c2ca"
              activeTabTextColor="#b392ac"
              hoverTabColor="#b392ac"
              hoverTextColor="#fff"
              customContentClass="bg-white"
              buttonBorder="border-l-2 border-white"
            />
          </div>
        </div>
      ) : (
        <p>No student details found.</p> // Fallback if no data
      )}
    </div>
  );
};

export default StudentDetailsPage;
