/**
 * Generates columns dynamically based on the keys in the provided data.
 * Optionally allows overriding headers for specific keys.
 * @param data Array of objects representing rows.
 * @param visibleColumns Array of column keys in the desired order.
 * @param headerOverrides Optional object to override column headers.
 */

export type CommonHeaderOverrides = {
  [key: string]: string;
};

export const commonHeaderOverrides: CommonHeaderOverrides = {
  id: "ID",
  firstName: "First Name",
  lastName: "Last Name",
  fullName: "Full Name",
  email: "Email",
  phoneNumber: "Phone Number",
  address: "Address",
  status: "Status",
  add: "",
  remove: "",
  division: "Section",
  studentId: "Student ID",
  name: "Name",
  standard: "Standard",
  section: "Section",
  addReasonForAbsent: "Reason For Absent",
  removeReasonForAbsent: "Reason For Absent",
  userName: "User Name",
  password: "Password",
  accountLocked: "Account Locked",
  accountActive: "Account Active",
  invalidLoginAttempt: "Invalid Login Attempt",
};
