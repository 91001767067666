import React, { ReactNode, useEffect, useMemo, useState } from "react";
import Pagination from "./Pagination";
import TableOptionsMenu from "./TableOptionsMenu";
import { commonHeaderOverrides } from "../../utils/tableColumns";
import customRenderers from "../../utils/tableCustomRenderers";
import LoadingSpinner from "./LoadingSpinner";
import useSorting from "../../hooks/dataTable/useSorting";
import { useScrollable } from "../../hooks/dataTable/useTableScroll";

interface DataTableProps {
  data: any[];
  pageSizeOptions?: number[];
  onCellClick?: (columnKey: string, row: any) => void;
  clickableColumns?: string[];
  visibleColumns: string[]; // prop to specify which columns should be visible
  nonSortableColumns?: string[]; // prop to specify columns where sorting should be disabled
  tableName?: string;
  showCheckboxColumn?: boolean;
  flexColumns?: string[]; // prop to specify columns that require flex layout
  month?: string;
  days?: number;
  filtersCleared?: boolean;
  emptyColumnHeadings?: string[];
  tableFooter?: ReactNode;
  customRenderedColumns?: string[]; // prop to specify which columns should use custom renderers
  showRowHover?: boolean;
  defaultPageSize?: number;
}

const DataTable: React.FC<DataTableProps> = ({
  data,
  pageSizeOptions = [5, 10, 20],
  onCellClick,
  clickableColumns = [],
  visibleColumns,
  nonSortableColumns = [],
  tableName = "",
  showCheckboxColumn = true,
  flexColumns = "id",
  month = "",
  days,
  filtersCleared = false,
  emptyColumnHeadings = [],
  tableFooter,
  customRenderedColumns = [],
  showRowHover = true,
  defaultPageSize = 5,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectedAllRows, setSelectedAllRows] = useState(false);

  // Generate columns based on visibleColumns and headerOverrides, independent of data
  const columns = useMemo(() => {
    // Ensure columns are generated based on visibleColumns, even if data is empty
    return visibleColumns.map((key) => {
      return {
        key,
        header: emptyColumnHeadings.includes(key)
          ? ""
          : commonHeaderOverrides[key] ||
            key.charAt(0).toUpperCase() + key.slice(1),
      };
    });
  }, [visibleColumns, emptyColumnHeadings, commonHeaderOverrides]);

  // Use the sorting custom hook
  const { sortedData, handleSort, getSortIcon } = useSorting(
    data,
    nonSortableColumns
  );

  // Scrollable functionality using the custom hook
  const { isScrollable, tableContainerRef } = useScrollable(
    data,
    pageSizeOptions
  );

  // Pagination logic
  const startIndex = (currentPage - 1) * pageSize;

  const paginatedData = sortedData?.slice(startIndex, startIndex + pageSize);

  // Handle selecting individual row checkboxes
  const handleRowCheckboxChange = (rowIndex: number) => {
    const updatedSelectedRows = new Set(selectedRows);
    if (updatedSelectedRows.has(rowIndex)) {
      updatedSelectedRows.delete(rowIndex); // Unselect
    } else {
      updatedSelectedRows.add(rowIndex); // Select
    }
    setSelectedRows(updatedSelectedRows);
  };

  // Handle selecting the header checkbox (select all rows)
  const handleHeaderCheckboxChange = () => {
    if (selectedAllRows) {
      setSelectedRows(new Set()); // Deselect all
    } else {
      const allRowIndices = paginatedData.map((_, index) => index + startIndex);
      setSelectedRows(new Set(allRowIndices)); // Select all
    }
    setSelectedAllRows(!selectedAllRows);
  };

  const handleCellClick = (key: string, row: any) => {
    if (onCellClick && clickableColumns.includes(key)) {
      onCellClick(key, row); // Trigger the click handler for specific columns
    }
  };

  // Handler to update page size from Pagination component
  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to the first page when page size changes
  };

  const shouldUseCustomRender = (columnKey: string) => {
    if (customRenderedColumns.length > 0) {
      return customRenderedColumns.includes(columnKey);
    }
    return true; // If no customRenderedColumns prop is passed, apply custom renders by default
  };

  const customRenderersForColumns = (
    columnKey: string,
    value: any,
    row: any
  ) => {
    if (shouldUseCustomRender(columnKey)) {
      return customRenderers[columnKey]?.(value, row) || value;
    }
    return value;
  };

  return (
    <div
      className={`p-4 bg-white rounded-md shadow-md w-auto font-tables text-sm`}
    >
      <h2 className="text-xl mb-3">{tableName}</h2>

      {/* Pass selected rows data to OptionsMenu */}
      <TableOptionsMenu
        selectedRows={data?.filter((_, index) => selectedRows.has(index))}
      />

      {/* Table with scroll */}
      <div
        className={`relative ${
          isScrollable &&
          `after:content-[""] after:w-[13px] after:absolute after:top-0 after:right-0 after:bg-[#cef0fb] ${
            month && days ? "after:h-[4.2rem]" : "after:h-[1.9rem]"
          }`
        } `}
      >
        <div
          ref={tableContainerRef}
          className={`overflow-x-auto  ${
            pageSize > 10 ? "max-h-80 overflow-y-auto" : ""
          } table-scrollbar ${
            month && days ? "scrollable-with-monthdays" : ""
          }`}
        >
          <table className="w-full border-t border-r border-collapse">
            <thead
              className={`sticky bg-[#cef0fb] m-0 top-0 ${
                showCheckboxColumn
                  ? "shadow-[0_-1px_0_2px_#969ea5]"
                  : "border border-b-0 shadow-[0_0_0_1px_#808080cc]"
              } `}
            >
              {month && days && (
                <tr className="whitespace-break-spaces">
                  <th
                    colSpan={100}
                    className="text-lg py-1 border-b-2 border-b-[#808080cc] shadow-[0_0_0_1px_#808080cc]"
                  >
                    <div className="flex items-center justify-evenly">
                      <div>
                        <span className="text-[#EF233C]">Month: </span>
                        <span className="text-[#14524F]">{month}</span>
                      </div>
                      <div>
                        <span className="text-[#660058]"> Days:</span>
                        <span className="text-[#636018]">{days}</span>
                      </div>
                    </div>
                  </th>
                </tr>
              )}
              <tr className="whitespace-break-spaces">
                {showCheckboxColumn && (
                  <th className="p-1 border-t border-l">
                    <div className="flex justify-center items-center">
                      <input
                        type="checkbox"
                        className="cursor-pointer w-4 h-4"
                        checked={selectedAllRows} // Controlled checkbox
                        onChange={handleHeaderCheckboxChange}
                      />
                    </div>
                  </th>
                )}
                {columns.map((col) => (
                  <th
                    key={col.key}
                    className="p-1 text-left cursor-pointer border border-[#969ea5]"
                    onClick={() => {
                      if (!nonSortableColumns.includes(col.key)) {
                        // Only allow sorting if not in nonSortableColumns
                        handleSort(col.key);
                      }
                    }}
                  >
                    <div className="flex items-center space-x-2 ">
                      <span className="font-tables">{col.header}</span>
                      {!nonSortableColumns.includes(col.key) &&
                        getSortIcon(col.key)}{" "}
                      {/* Render sort icon only if sortable */}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData?.length > 0 || data?.length > 0 ? (
                paginatedData.map((row, rowIndex) => {
                  const rowKey = row.id; // Assuming 'id' is unique, use it as the key
                  const isSelected = selectedRows.has(rowIndex + startIndex); // Check if the row is selected
                  return (
                    <tr
                      key={rowIndex}
                      className={`${
                        showRowHover && "hover:bg-[#bdbbbb]"
                      } whitespace-break-spaces ${
                        isSelected && "bg-[#e5e5e5]"
                      }`}
                    >
                      {showCheckboxColumn && (
                        <td className="p-1 border border-[#969ea5]">
                          <div className="flex justify-center items-center">
                            <input
                              type="checkbox"
                              className="cursor-pointer w-4 h-4"
                              checked={isSelected} // Controlled checkbox for row
                              onChange={() =>
                                handleRowCheckboxChange(rowIndex + startIndex)
                              } // Update row selection
                            />
                          </div>
                        </td>
                      )}
                      {columns.map((col) => {
                        const value = row[col.key];
                        const content =
                          customRenderersForColumns(col.key, value, row) ||
                          value;
                        const isFlex = flexColumns?.includes(col.key); // Check if this column needs flex

                        return (
                          <td
                            key={col.key}
                            className={`p-1 border border-[#969ea5] font-tables ${
                              clickableColumns.includes(col.key)
                                ? "cursor-pointer"
                                : ""
                            }`}
                            onClick={() => {
                              if (clickableColumns.includes(col.key)) {
                                handleCellClick(col.key, row);
                              }
                            }}
                          >
                            {isFlex ? (
                              <div className="flex justify-center items-center ">
                                {content}
                              </div>
                            ) : (
                              content
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={columns?.length + 1} // Span across all columns, including the checkbox column
                    className="text-center p-4 font-tables border border-[#969ea5]"
                  >
                    {filtersCleared ? "No data to display" : "No data found"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Component */}
      {data?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalEntries={data.length}
          pageSizeOptions={pageSizeOptions}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={handlePageSizeChange}
          tableFooter={tableFooter}
          pageSize={pageSize}
        />
      )}
    </div>
  );
};

export default DataTable;
