import React from "react";

interface CustomTableProps {
  data: any[];
  visibleColumns: string[]; // The columns that should be visible in the table
  nonSortableColumns?: string[]; // Columns that should not be sortable
  clickableColumns?: string[]; // Columns that should be clickable
  columnHeaders: { [key: string]: string }; // Custom column headers mapping
  renderCell: (row: any, column: string) => JSX.Element;
}

const CustomTable: React.FC<CustomTableProps> = ({
  data,
  visibleColumns,
  nonSortableColumns = [],
  clickableColumns = [],
  columnHeaders,
  renderCell,
}) => {
  return (
    <div className="max-h-[400px] overflow-y-auto relative mb-5">
      <table className="w-full border-collapse table-auto">
        {/* Header Row */}
        <thead>
          <tr>
            {visibleColumns.map((column) => (
              <th
                key={column}
                className="sticky top-0 bg-gray-100 z-10 p-2 text-left border-b border-t border-gray-300 min-w-[120px] break-words"
              >
                {!nonSortableColumns.includes(column) ? (
                  <span
                    onClick={() =>
                      clickableColumns.includes(column) &&
                      console.log(`Column clicked: ${column}`)
                    }
                    className={`${
                      clickableColumns.includes(column)
                        ? "cursor-pointer"
                        : "cursor-default"
                    }`}
                  >
                    {columnHeaders[column] || column}{" "}
                    {/* Use the custom header or fallback to column key */}
                  </span>
                ) : (
                  columnHeaders[column] || column
                )}
              </th>
            ))}
          </tr>
        </thead>

        {/* Body Rows */}
        <tbody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {visibleColumns.map((column) => (
                  <td
                    key={column}
                    className="p-2 border-b border-gray-300 whitespace-nowrap overflow-hidden text-ellipsis min-w-[120px]"
                  >
                    {/* {row[column] || "-"} Default to "-" if no data */}
                    {renderCell(row, column)}{" "}
                    {/* Render custom content for each cell */}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={visibleColumns.length} className="text-center py-2">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
