import React, { useEffect, useState } from "react";
import DataTable from "../../components/common/DataTable";
import FilterPanel from "../../components/common/FilterPanel";
import { useLocation, useNavigate } from "react-router-dom";
import { studentListvisibleColumns } from "../../utils/tableVisibleColumns";
import { searchedStudent, standardAndDivision } from "../../services/api";
import Toast from "../../components/common/Toast";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const StudentsList: React.FC = () => {
  const navigate = useNavigate(); // Initialize navigate

  // Dynamic Filter States
  const [filters, setFilters] = useState({
    standard: "",
    section: "",
  });

  const [filteredData, setFilteredData] = useState([]); // Use the Student type here
  const [searchTerm, setSearchTerm] = useState(""); // Search term state

  const [filtersLoading, setFiltersLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  // State for dynamic standard and section options
  const [standardAndSectionData, setStandardAndSectionData] =
    useState<any>(null);
  const [selectedStandard, setSelectedStandard] = useState("");

  const [filtersCleared, setFiltersCleared] = useState(false);

  const [filtersSubmitted, setFiltersSubmitted] = useState(false);

  const [toastConfig, setToastConfig] = useState<{
    show: boolean;
    message: string;
    type: "error" | "success" | "info";
    duration: number;
  }>({
    show: false,
    message: "",
    type: "error",
    duration: 3000,
  });

  const location = useLocation(); // Use location to detect if coming from StudentDetailsPage

  useEffect(() => {
    const savedState = sessionStorage.getItem("studentsListState");

    if (location.state?.fromStudentDetails && savedState) {
      const parsedState = JSON.parse(savedState);

      setFilters({
        standard: parsedState.filters.standard,
        section: parsedState.filters.section,
      });

      setSearchTerm(parsedState.searchTerm);

      setFilteredData(parsedState.filteredData);

      setStandardAndSectionData(parsedState.standardAndSectionData);

      setSelectedStandard(parsedState.selectedStandard);
    } else {
      // If not from StudentDetailsPage, reset session storage
      sessionStorage.removeItem("studentsListState");

      setFilters({
        standard: "",
        section: "",
      });

      setSearchTerm("");

      setFilteredData([]);

      const fetchFilterData = async () => {
        setFiltersLoading(true);
        try {
          const data = await standardAndDivision();
          if (data) {
            setStandardAndSectionData(data.standardAndDivisionMap);

            const defaultStandard = data.defaultStandard.split(":")[1].trim();
            const defaultSection = data.defaultDivision;

            // set defualt filters
            setFilters({
              standard: defaultStandard,
              section: defaultSection,
            });

            // Set the selected standard state immediately based on the default data
            setSelectedStandard(data.defaultStandard);

            // filter the data with defualt filters
            const filtered = data?.studentRespList?.filter(
              (student: any) =>
                student.standard === defaultStandard &&
                student.division === defaultSection
            );
            setFilteredData(filtered);
          }
        } catch (error) {
          console.error("Error fetching filter data:", error);
        } finally {
          setFiltersLoading(false);
        }
      };

      fetchFilterData();
    }
  }, [location.state?.fromStudentDetails]);

  // Handle Search Term Change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Handle Filter Change
  const handleFilterChange = (key: string, value: string) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    setFiltersSubmitted(false);
  };

  // Handle Filter Submit
  const handleFilterSubmit = async () => {
    if (!filters.standard) {
      setToastConfig({
        show: true,
        message: "Please select a standard.",
        type: "error",
        duration: 3000,
      });
      return;
    }

    setDataLoading(true);

    const selectedStandardKey = Object.keys(standardAndSectionData).find(
      (key) => key.split(":")[1] === filters.standard
    );

    const params = {
      standard: selectedStandardKey?.split(":")[0] || "",
      studentNameOrId: searchTerm,
      division: filters.section || "",
    };

    try {
      const response = await searchedStudent(params);

      if (response) {
        setFilteredData(response); // Assuming the data returned from API is in 'data' field
      } else {
        setFilteredData([]);
      }
    } catch (error) {
      console.error("Error fetching students:", error);
      setFilteredData([]);
    } finally {
      setDataLoading(false); // Hide loading spinner
    }

    setFiltersCleared(false);

    setFiltersSubmitted(true);
  };

  // Handle Clear Button Click
  const handleClearSubmit = () => {
    if (filters.section === "" && filters.section === "") {
      setToastConfig({
        show: true,
        message: "Select filters to clear.",
        type: "error",
        duration: 3000,
      });
      return;
    }

    // Clear filters
    setFilters({
      standard: "",
      section: "",
    });

    setSearchTerm("");

    setFilteredData([]); // Reset filtered data

    setFiltersCleared(true);
  };

  // Handle Cell Click
  const handleCellClick = (columnKey: string, row: any) => {
    if (columnKey === "id") {
      if (filtersSubmitted) {
        sessionStorage.setItem(
          "studentsListState",
          JSON.stringify({
            filters,
            searchTerm,
            filteredData,
            standardAndSectionData,
            selectedStandard,
          })
        );
      }

      navigate(`/student_management/student_list/student_details/${row.id}`, {
        state: { from: "/student_management/student_list" }, // Set the path here
      }); // Navigate to the student details page
    }
  };

  const standardValues = Object.keys(standardAndSectionData || {}).map(
    (key) => key.split(":")[1]
  );

  const handleStandardChange = (value: string) => {
    // If standard is empty, clear the section filter as well
    if (!value) {
      setFilters((prev) => ({
        ...prev,
        standard: value,
        section: "", // Clear section filter
      }));
    } else {
      const selectedStandardKey = Object.keys(standardAndSectionData).find(
        (key) => key.split(":")[1] === value
      );

      // Update the sections when standard changes
      if (selectedStandardKey) {
        setSelectedStandard(selectedStandardKey);

        const sections = standardAndSectionData[selectedStandardKey];

        // Update the filters to set the section based on the first available section of the selected standard
        setFilters((prev) => ({
          ...prev,
          standard: value,
          section: sections && sections.length > 0 ? sections[0] : "", // Set the first section by default
        }));

        // Ensure that the section options are updated immediately in the filter UI
        setFilters((prev) => ({
          ...prev,
          section: sections && sections.length > 0 ? sections[0] : "", // Set the first section
        }));
      }
    }
  };

  // Dynamic Filter Configuration
  const filterOptions = [
    {
      label: "Standard",
      type: "select" as const,
      options: standardValues,
      value: filters.standard,
      onChange: handleStandardChange,
      mandatory: true,
    },
    {
      label: "Section",
      type: "select" as const,

      options: filters.standard
        ? standardAndSectionData?.[selectedStandard] || []
        : [],
      value: filters.section,
      onChange: (value: string) => handleFilterChange("section", value),
    },
  ];

  return (
    <>
      {filtersLoading ? (
        <LoadingSpinner message="Loading..." />
      ) : (
        <>
          {dataLoading && <LoadingSpinner bgBlur={true} />}
          <div className="bg-slate-100">
            {/* Filter Panel */}
            <FilterPanel
              filters={filterOptions}
              onFilterSubmit={handleFilterSubmit}
              buttonText="Search"
              containerStyle="max-w-full"
              searchTerm={searchTerm}
              onSearchChange={handleSearchChange}
              searchBarClassName="w-full"
              placeholder="Student name..."
              onClearSubmit={handleClearSubmit}
            />

            <div className="mt-3">
              <DataTable
                data={filteredData}
                // onCellClick={(columnKey, row) => console.log(columnKey, row)}
                onCellClick={handleCellClick}
                clickableColumns={["id"]}
                visibleColumns={studentListvisibleColumns}
                filtersCleared={filtersCleared}
              />
            </div>

            <Toast
              message={toastConfig.message}
              type={toastConfig.type}
              show={toastConfig.show}
              onClose={() =>
                setToastConfig((prev) => ({ ...prev, show: false }))
              }
              duration={toastConfig.duration}
              position="top"
            />
          </div>
        </>
      )}
    </>
  );
};
export default StudentsList;
