import React, { createContext, useContext, useState, useCallback } from "react";
import { studentsInfo } from "../services/api";

// Context Type
interface StudentsContextProps {
  studentsData: {
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    gender: string;
    phoneNumber: string;
    address: string;
    status: string;
    standard: string;
    division: string;
  }[];
  loading: boolean;
  error: string | null;
  loginStatus: boolean;
  setAuthToken: (authToken: string | null) => void;
  fetchStudentsData: () => void; // Add method to fetch data
}

// Create Context
const StudentsContext = createContext<StudentsContextProps | undefined>(
  undefined
);

// Custom Hook
export const useStudents = () => {
  const context = useContext(StudentsContext);
  if (!context) {
    throw new Error("useStudents must be used within a StudentsProvider");
  }
  return context;
};

// Provider Component
export const StudentsProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [studentsData, setStudentsData] = useState<
    {
      id: number;
      firstName: string;
      lastName: string;
      fullName: string;
      email: string;
      gender: string;
      phoneNumber: string;
      address: string;
      status: string;
      standard: string;
      division: string;
    }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loginStatus, setLoginStatus] = useState<boolean>(false);
  const [authToken, setAuthToken] = useState<string | null>(
    sessionStorage.getItem("authToken")
  );

  // Memoize the function to fetch students data so it doesn't recreate unnecessarily
  const fetchStudentsData = useCallback(async () => {
    if (!authToken) {
      setError("User not authenticated.");
      return;
    }

    setLoading(true);
    try {
      const data = await studentsInfo();
      setStudentsData(data);
      setLoginStatus(true);
    } catch (err: any) {
      console.error("Error fetching students:", err);
      setLoginStatus(false);
    } finally {
      setLoading(false);
    }
  }, [authToken]);

  const updateAuthToken = (token: string | null) => {
    if (token) {
      sessionStorage.setItem("authToken", token);
    } else {
      sessionStorage.removeItem("authToken");
    }
    setAuthToken(token);
  };

  return (
    <StudentsContext.Provider
      value={{
        studentsData,
        loading,
        error,
        loginStatus,
        setAuthToken: updateAuthToken,
        fetchStudentsData,
      }}
    >
      {children}
    </StudentsContext.Provider>
  );
};
