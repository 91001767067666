import React, { useState } from "react";
import { motion } from "framer-motion";
import { BiSolidDownArrow, BiSolidRightArrow } from "react-icons/bi";

// Define a type for the props to be passed to the accordion component
interface AccordionProps {
  title: string;
  content: React.ReactNode; // The content can be anything (object, array, JSX)
  defaultOpen?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  content,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleAccordion = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className="accordion-container mt-4 py-4 px-7 border rounded-lg shadow-md bg-white">
      <div
        className="accordion-header cursor-pointer flex items-center justify-between"
        onClick={toggleAccordion}
      >
        {/* Rotate arrow icon smoothly with Framer Motion */}
        <motion.span
          animate={{
            rotate: isOpen ? 90 : 0, // Rotate by 180 degrees when open
          }}
          transition={{ duration: 0.3 }}
        >
          <BiSolidRightArrow size={18} />
        </motion.span>

        <h3
          className="text-lg font-semibold py-1 px-6 rounded-md text-white"
          style={{
            background: "linear-gradient(to bottom right,#00b3cc, #a3d046 )",
          }}
        >
          {title}
        </h3>
      </div>

      {/* Framer Motion for smooth animation */}
      <motion.div
        className="accordion-content"
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        style={{ overflow: "hidden" }}
      >
        <div className="px-2 py-4">{content}</div>
      </motion.div>
    </div>
  );
};

export default Accordion;
